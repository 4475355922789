import { ComposeText } from 'src/app/commons/models/inbox.models';
import { Component, OnInit } from '@angular/core';
import { CreateComponent } from '../create.component';
import { PhoneNumbers } from 'src/app/commons/models/broadcast.models';

@Component({
  selector: 'app-compose',
  templateUrl: './compose.component.html',
  styleUrls: ['./compose.component.scss']
})
export class ComposeComponent extends CreateComponent implements OnInit {
  private $phoneNumbers: PhoneNumbers[] = Array<PhoneNumbers>();

  ngOnInit() {
    super.ngOnInit();
    this.broadcastService.fetchPhoneNumber();

    this.broadcastService.phoneNumbers.subscribe((resp: PhoneNumbers[]) => {
      this.$phoneNumbers = resp;
      if (!!this.$phoneNumbers.length) { this.form.form.patchValue({number: resp[0].phone_number}); }
    });
  }

  get bodyMessageCount() {
    if (!this.form.form.controls.body.value) {
      return 120;
    } else {
      return 120 - this.form.form.controls.body.value.length;
    }
  }

  get phoneNumbers() {
    return this.$phoneNumbers;
  }

  onNext({value, valid}: {value: ComposeText, valid: boolean}) {

    if (valid) {
      this.inboxService.updateTextMessage(value);
      this.state.go('inbox-contacts');
    }
  }
}
