import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';

import creditCardType from 'credit-card-type';

export class CreditCardForm {
  public form: FormGroup;
  public errors = [];

  constructor(data) {
    // Initialize the form builder
    this.form = new FormBuilder().group({
      billing_address: new FormBuilder().group({
        first_name: new FormControl(null, [Validators.required]),
        last_name: new FormControl(null, [Validators.required]),
        extended_address: new FormControl(null, [Validators.required]),
        line2: new FormControl(null, []),
        locality: new FormControl(null, [Validators.required]),
        country_code_alpha3: new FormControl('USA', [Validators.required]),
        region: new FormControl(null, [Validators.required]),
        postal_code: new FormControl(null, [Validators.required]),
        phone: new FormControl(null, [Validators.required]),
      })
    });
  }

  // check form if all field is valid
  get isValid() {
    return this.form.valid;
  }

  setCardType() {
    return new Promise((resolve, reject) => {
      const cardNumber = this.form.get('number').value;
      const cardType = creditCardType(cardNumber)[0].type;
      this.form.patchValue({type: cardType});

      resolve(cardType);
    });
  }

  // check if form field is valid
  valid(f: any) {
    return (!this.form.get(f).valid && this.form.get(f).touched);
  }

  updateValue(f, value) {
    this.form.get(f).setValue(value);
  }

  hasError(f) {
    this.errors.forEach(item => {
      if (item.field === f) {
        console.log(item);
        return true;
      }
    });

    return false;
  }
}
