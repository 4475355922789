import { urlsafe } from "../utils/http.utils";
import { API_URL } from "./conf.constants";

/* USERS
 */
export const API_USERS = urlsafe(API_URL, "users");

/* CORE
 */
export const API_CORE = urlsafe(API_URL, "core");
export const API_CREDIT_PAYMENT = urlsafe(API_CORE, "payment");
export const API_CREDIT_BALANCE = urlsafe(API_CORE, "credits");

/* CORE V2
 */
export const API_CORE_V2 = urlsafe(API_CORE, "v2");
export const API_PAYMENT_METHOD = urlsafe(API_CORE_V2, "payment-method");

/* AUTH
 */
export const API_AUTH = urlsafe(API_USERS, "auth");
export const API_AUTH_LOGIN = urlsafe(API_AUTH, "login");
export const API_AUTH_REGISTER = urlsafe(API_AUTH, "register");
export const API_VERIFY_GOOGLE_AUTH = urlsafe(API_AUTH, "verify-google-auth");

/* PROSPECTS
 */
export const API_PROSPECTS = urlsafe(API_URL, "prospects");
export const API_PROSPECTS_UPDATE = urlsafe(API_PROSPECTS, "update");
export const USER_PHONE_NUMBER = urlsafe(API_PROSPECTS, "phone-numbers");
export const API_CONTACTS = urlsafe(API_PROSPECTS, "contacts");
export const API_CONTACT_GROUP = urlsafe(API_PROSPECTS, "contact-list");

/* LISTINGS
 */
export const API_LISTINGS = urlsafe(API_URL, "listings");

/* INBOX
 */
export const API_EMAIL_SEND = urlsafe(API_PROSPECTS, "send");
export const API_INBOX = urlsafe(API_PROSPECTS, "inbox");
export const API_INBOX_THREAD = urlsafe(API_PROSPECTS, "inbox-thread");
export const API_SCHEDULER = urlsafe(API_PROSPECTS, "scheduler");

/* BROADCAST
 */
export const API_BROADCAST = urlsafe(API_URL, "broadcast");
export const API_TEXT_SEND = urlsafe(API_BROADCAST, "send-text");
export const API_AVAILABLE_PHONE = urlsafe(API_BROADCAST, "available-phone");
export const API_BROADCAST_LIST = urlsafe(API_BROADCAST, "broadcasts");
export const API_BROADCAST_DETAIL = urlsafe(API_BROADCAST, "broadcasts");
export const API_BROADCAST_MESSAGE = urlsafe(API_BROADCAST, "message");

/* Transactions
 */
export const API_TRANSACTIONS = urlsafe(API_URL, "transactions");
export const API_BUY_CREDIT = urlsafe(API_TRANSACTIONS, "buy-credits");
export const API_PURCHASE = urlsafe(API_TRANSACTIONS, "purchase");

/**
 * VOICE
 */
export const API_VOICE = urlsafe(API_BROADCAST, "voice");
export const API_VOICE_RECORDS = urlsafe(API_VOICE, "records");
export const API_VOICE_RECORDING = urlsafe(API_VOICE, "recording");
export const API_VOICE_RECORDING_PHONE = urlsafe(API_VOICE_RECORDING, "phone");
export const API_VOICE_RECORDING_DELETE = urlsafe(
  API_VOICE_RECORDING,
  "delete"
);
export const API_OUTBOUND_CALL = urlsafe(API_VOICE, "outbound", "call");
