import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';

export class ComposeEmailForm {
  public form: FormGroup;
  public errors: string = null;
  public submitted = false;

  constructor(data) {
    // Initialize the form builder
    this.form = new FormBuilder().group({
      subject: new FormControl(null, [Validators.required]),
      service: new FormControl(0, [Validators.required]),
      body: new FormControl(null, [Validators.required]),
      prospect: new FormControl(null, [])
    });
  }

  // check form if all field is valid
  get isValid() {
    return this.form.valid;
  }

  // check if form field is valid
  valid(f: any) {
    return (!this.form.get(f).valid && this.form.get(f).touched);
  }

  updateValue(f, value) {
    this.form.get(f).setValue(value);
  }
}

export class ComposeTextForm {
  public form: FormGroup;
  public errors: string = null;
  public submitted = false;

  constructor(data) {
    // Initialize the form builder
    this.form = new FormBuilder().group({
      number: new FormControl(null, [Validators.required]),
      service: new FormControl(1, [Validators.required]),
      body: new FormControl(null, [Validators.required]),
      prospect: new FormControl(null, [])
    });
  }

  // check form if all field is valid
  get isValid() {
    return this.form.valid;
  }

  // check if form field is valid
  valid(f: any) {
    return (!this.form.get(f).valid && this.form.get(f).touched);
  }

  updateValue(f, value) {
    this.form.get(f).setValue(value);
  }
}


export class ReplyTextForm {
  public form: FormGroup;
  public errors: string = null;
  public submitted = false;

  constructor(data) {
    // Initialize the form builder
    this.form = new FormBuilder().group({
      body: new FormControl(null, [Validators.required])
    });
  }

  // check form if all field is valid
  get isValid() {
    return this.form.valid;
  }

  // check if form field is valid
  valid(f: any) {
    return (!this.form.get(f).valid && this.form.get(f).touched);
  }

  updateValue(f, value) {
    this.form.get(f).setValue(value);
  }
}