import * as _ from "lodash";
import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { VoiceService } from "src/app/commons/services/voice.service";
import { VoiceRecordsModel } from "src/app/commons/models/voice.models";
import { Subscription } from "rxjs";
import { DeleteconfirmComponent } from "./deleteconfirm/deleteconfirm.component";

@Component({
  selector: "app-selectrecords",
  templateUrl: "./selectrecords.component.html",
  styleUrls: ["./selectrecords.component.scss"],
})
export class SelectrecordsComponent implements OnInit {
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() openModal: EventEmitter<any> = new EventEmitter();
  @Input() broadcastForm?: any = null;
  private voiceRecords$: VoiceRecordsModel[] = Array<VoiceRecordsModel>();
  voicesub: Subscription;

  constructor(
    private voiceService: VoiceService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    console.log("select a sound..");
    this.voicesub = this.voiceService.voiceRecords$.subscribe(
      (records: VoiceRecordsModel[]) => {
        this.voiceRecords$ = _.orderBy(records, ["date_created"], ["desc"]);
      }
    );
  }

  openVoiceRecordCreateModal() {
    // this.closeModal.emit('selection');
    this.openModal.emit("create");
  }

  // close voice record selection
  closeSelectionModal() {
    this.closeModal.emit({
      modalAction: "selection",
      form: this.broadcastForm,
    });
  }

  get voiceRecords() {
    return this.voiceRecords$;
  }

  fetchVoiceRecords() {
    this.voiceService.getVoiceRecords.subscribe(
      (records: VoiceRecordsModel[]) => {
        this.voiceRecords$ = _.orderBy(records, ["date_created"], ["desc"]);
      }
    );
    return [];
  }

  refresh() {
    this.voiceService.fetchVoiceRecords();
  }

  deleteRecord(record, index) {
    this.modalService.open(DeleteconfirmComponent).result.then((resp) => {
      if (resp) {
        this.voiceService
          .deleteVoiceRecord(record.rsid)
          .subscribe((data: any) => {
            this.voiceRecords.splice(index, 1);
          });
      }
    });
  }

  onNext() {
    this.closeSelectionModal();
  }

  ngOnDestroy() {
    if (this.voicesub) this.voicesub.unsubscribe();
  }
}
