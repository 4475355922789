import { Param } from '@uirouter/angular';


export function cleanData(obj) {
  Object.keys(obj).forEach(key => obj[key] == null && delete obj[key]);
  return obj;
}

// tslint:disable: variable-name
export class PhoneNumbers {
  sid: string = null;
  phone_number: string = null;
  friendly_name: string = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class GetPhoneParams {
  type = 'local';
  country_code = 'US';
  area_code: string = null;
  contains: string = null;
  city: string = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }

  cleanedData() {
    return cleanData(this);
  }
}

export class BroadcastLabel {
  id: string = null;
  name: string = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class TextStatus {
  total: number = null;
  delivered: number = null;
  sent: number = null;
  failed: number = null;
  undelivered: number = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class VoiceStatus {
  total: number = null;
  initiated: number = null;
  answered: number = null;
  noAnswer: number = null;
  busy: number = null;
  completed: number = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class Broadcast {
  id: string = null;
  isSelected = false;
  name: string = null;
  type: string = null;
  labels: BroadcastLabel[] = Array<BroadcastLabel>();
  message_status: TextStatus = new TextStatus();
  voice_status: VoiceStatus = new VoiceStatus();
  date_created: string = null;
  date_updated: string = null;
  recipients: any;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class BroadcastList {
  count: number = null;
  next: string = null;
  previous: string = null;
  results: Broadcast[] = Array<Broadcast>();

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class BroadcastMessage {
  id: string = null;
  sid: string = null;
  body: string = null;
  sender: string = null;
  outbound: any;
  inbox: number = null;
  status: string = null;
  broadcast: number = null;
  date_created: string = null;
  receiver_number: string = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class BroadcastMessageList {
  count: number = null;
  next: string = null;
  previous: string = null;
  results: BroadcastMessage[] = Array<BroadcastMessage>();

  constructor(data = {}) {
    Object.assign(this, data);
  }
}


export class BroadcastListParams {
  type: any = null;
  date: string = null;
  from: string = null;
  to: string = null;
  search: string = null;
  page: number = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }

  cleanedData() {
    return cleanData(this);
  }
}

export class BroadcastTextRecipientLocation {
  id: string = null;
  name: string = null;
  coordinates: string = null;
  count: any;
}

export class BroadcastReceiver {
  id: string = null;
  prospect: string = null;
  contact_number: string = null;
  location: BroadcastTextRecipientLocation = new BroadcastTextRecipientLocation();

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class BroadcastTextRecipient {
  id: string = null;
  status: string = null;
  receiver: BroadcastReceiver = new BroadcastReceiver();

  constructor(data = {}) {
    Object.assign(this, data);
  }
}


export class BroadcastMapMarker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
