import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-broadcast-status',
  templateUrl: './broadcast-status.component.html',
  styleUrls: ['./broadcast-status.component.scss']
})
export class BroadcastStatusComponent implements OnInit {
  @Input() totalCount: any;
  @Input() name: any;
  @Input() count: any;

  constructor() { }


  ngOnInit() {
  }

  splitText(text) {
    return text.split(/(?=[A-Z])/).join(' ');
  }

}
