import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { urlsafe, queryparams } from '../utils/http.utils';
import { API_LISTINGS } from '../constants/api.constants';
import { Properties } from '../models/listings.models';

@Injectable({
  providedIn: 'root'
})
export class ListingsService {

  private properties$: BehaviorSubject<Properties> = new BehaviorSubject(new Properties());

  constructor(
    private http: HttpClient
  ) { }

  get properties() {
    return this.properties$.asObservable();
  }

  getProperties() {
    return this.http.get(API_LISTINGS )
      .toPromise()
      .then((resp) => {
        const results = {results: resp};
        this.properties$.next(new Properties(results));
      });
  }
}
