import { Component, OnInit } from '@angular/core';
import { Broadcast, BroadcastMessage } from 'src/app/commons/models/broadcast.models';
import { BroadcastService } from 'src/app/commons/services/broadcast.service';

@Component({
  selector: 'app-broadcast-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class BroadcastTextComponent implements OnInit {
  public broadcast: Broadcast = new Broadcast();
  public recentBroadcast: BroadcastMessage[] = Array<BroadcastMessage>();
  public receiver: any = '';
  constructor(
    public broadcastService: BroadcastService,
  ) { }

  async ngOnInit() {
    await this.broadcastService.broadcastDetail.subscribe(resp => {
      this.broadcast = resp;
    });
    await this.broadcastService.broadcastDetailOverview.subscribe( (resp: any) => {
      if (Array.isArray(resp)) {
        this.recentBroadcast = resp;
        resp.map((activity) => {
          if (activity.receiver_number) {
            this.receiver = activity.receiver_number;
          }
        });
      }
    });
  }


}
