import * as _ from 'lodash';
import { StateService } from '@uirouter/angular';
import { Component, OnInit,  } from '@angular/core';

import { InboxService } from 'src/app/commons/services/inbox.service';
import { InboxList } from 'src/app/commons/models/inbox.models';
import { User } from 'src/app/commons/models/user.models';

// import { WebhookService } from 'src/app/commons/services/websocket/webhook.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  public user: User = new User();
  public inboxList: InboxList = new InboxList();
  public isConversation: boolean = false;

  private inboxId = '';

  constructor(
    private inboxService: InboxService,
    private stateService: StateService
  ) { }

  ngOnInit() {

    this.inboxId = this.stateService.params.id;
    this.inboxService.fetchInboxList();
    this.getInboxList();
    this.inboxService.isFetchingInbox = true;

    // this.getInbox();
    // this.getInboxThread();
    if (this.inboxId !== '') {
      this.openConversation(this.inboxId);
    }
  }

  getInboxList() {
    this.inboxService.inboxList.subscribe((resp: InboxList) => {
      this.inboxList = resp;

      if (this.inboxList.list.length && this.inboxId === '') {
        const lastInbox = this.inboxList.list[0];
        this.inboxId = lastInbox.id;
        this.inboxService.fetchInbox(lastInbox.id);
        this.inboxService.fetchThread(lastInbox.id);
      }
    });
  }

  get inboxCount() {
    return !this.inboxList.list.length ? '' : this.inboxList.list.length;

  }

  isCurrentThread(id) {
    return this.inboxId === id;
  }

  openConversation(inbox) {
    this.inboxId = inbox.id;
    this.inboxService.isFetchingInbox = true;
    this.updateInboxIsRead(this.inboxId);
    this.inboxService.updateInbox(inbox);
    this.inboxService.fetchThread(this.inboxId, {}, true);
  }

  getInboxIndex(id) {
    return this.inboxList.list.findIndex(item => item.id === id);
  }

  updateInboxIsRead(id) {
    const index = this.getInboxIndex(id);
    this.inboxList.list[index].new_messages = 0;
    this.inboxService.updateInboxMessageIsRead(id);
  }  

  updateNewMessageCount(id) {
    const index = this.getInboxIndex(id);
    this.inboxList.list[index].new_messages++;

    // bring the inbox to the top list
    this.inboxList.list.unshift(this.inboxList.list.splice(index, 1)[0]);
  }
}
