import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';
import { BroadcastService } from 'src/app/commons/services/broadcast.service';
import { CustomError } from 'src/app/commons/models/exception.model';
import { CreditService } from 'src/app/commons/services/credit.service';
import { CreditBalance } from 'src/app/commons/models/credit.models';
import { NgbDateStruct, NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastListParams } from 'src/app/commons/models/broadcast.models';


@Component({
  selector: 'app-broadcast-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class BroadcastListComponent implements OnInit {

  public hoveredDate: NgbDate | null = null;
  public fromDate: NgbDate | null = null;
  public toDate: NgbDate | null = null;

  public broacastType = null;
  private $creditBalance: CreditBalance = new CreditBalance();

  public listParams: BroadcastListParams = new BroadcastListParams();
  public creditError: CustomError = new CustomError();
  public currPage = 1;
  public calendarModel: NgbDateStruct;
  public keywordSearch = null;
  public isBroadcast = false;

  constructor(
    public broadcastService: BroadcastService,
    private state: StateService,
    private creditService: CreditService,
    private calendar: NgbCalendar
  ) { }

  ngOnInit() {
    this.fetchCreditBalance();
    this.broacastType = this.state.params.type;
    this.listParams.type = this.typeNumber;
    this.fetchBroadcastList();
  }

  /**
   * DATE RANGE
   */
  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }
  /**
   * END OF DATE RANGE
   */

  get showDateRange() {
    if (this.toDate) {
      return `${this.dateFormatted(this.fromDate, '/')} - ${this.dateFormatted(this.toDate, '/')}`;
    }
    return this.dateFormatted(this.fromDate);


  }
  private dateFormatted(date: NgbDate, separator?: string) {
    if (separator) {
      return `${date.year}-${date.month}-${date.day}`.replace(/-/g, separator);
    }
    return `${date.year}-${date.month}-${date.day}`;

  }

  get isSelectedAll() {
    if (this.broadcastService.broadcasts.count) {
      return this.broadcastService.broadcasts.results.every(item => item.isSelected === true);
    } else {
      return false;
    }

  }

  get typeNumber() {
    const type = this.broacastType;

    switch (type) {
      case 'Text':
        return 0;
      case 'Voice':
        return 1;
      case 'IVR':
        return 2;
      case '':
        return '';
    }
  }

  allDate() {
    this.calendarModel = null;
    this.listParams.date = null;
    this.listParams.from = null;
    this.fetchBroadcastList();
  }

  dateSelect(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.listParams.from = this.dateFormatted(date);
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.listParams.to = this.dateFormatted(date);
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.listParams.to = null;
      this.listParams.from = this.dateFormatted(date);
    }
    this.fetchBroadcastList();
  }

  fetchBroadcastList() {
    this.isBroadcast = true;
    const params = this.listParams.cleanedData();
    this.broadcastService.fetchBroadcastList(params);
  }

  fetchCreditBalance() {
    this.creditService.creditBalance.subscribe((resp: CreditBalance) => {
      this.$creditBalance = resp;
    });
  }

  pageChanged(newPage: any) {
    const param = {page: newPage};
    this.listParams.page = newPage;
    this.currPage = newPage;

    this.fetchBroadcastList();
  }

  status(broadcast) {
    const isText = broadcast.type === 'Text';

    const totalCount = isText ? broadcast.message_status.total : broadcast.voice_status.total;
    const success = isText ? broadcast.message_status.delivered : broadcast.voice_status.completed;
    const percent = success && totalCount ? success / totalCount * 100 : 0;

    if (!totalCount) {return {color: 'text-danger', label: 'Stopped'}; }
    if (success === totalCount) {return {color: 'text-gray-600', label: 'Finished'}; }
    if (success !== totalCount) {return {color: 'text-warning', label: 'Incomplete'}; }

    return {color: 'text-warning', label: 'Incomplete'};
  }

  selectAll(e) {
    const isChecked = e.target.checked;
    this.setBroadcastIsSelect(isChecked);
  }

  setBroadcastIsSelect( value: boolean) {
    this.broadcastService.broadcasts.results.forEach(item => {
      item.isSelected = value;
    });
  }

  text() {
    const balance = this.$creditBalance.balance;
    if (!!balance) {
      this.state.go('inbox-compose');
    } else {
      this.creditError.code = 'credits';
      this.creditError.message = 'Insufficient credit balance.';
    }
  }

  computeProgress(broadcast) {
    const isText = broadcast.type === 'Text';
    const totalCount = isText ? broadcast.message_status.total : broadcast.voice_status.total;
    const success = isText ? broadcast.message_status.delivered : broadcast.voice_status.completed;
    const percent = success && totalCount ? success / totalCount * 100 : 0;

    return  `${percent}% ${success} of ${totalCount}`;
  }

  voice() {
    this.state.go('voice-create');
  }
}
