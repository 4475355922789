import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/commons/services/auth/auth.service';
import { InboxService } from 'src/app/commons/services/inbox.service';
import { BroadcastService } from 'src/app/commons/services/broadcast.service';
import { InviteForm } from 'src/app/commons/forms/invite.forms';
import { Invite } from 'src/app/commons/models/invite.models';
import { User } from 'src/app/commons/models/user.models';
import { ComposeText } from 'src/app/commons/models/inbox.models';
import { PhoneNumbers } from 'src/app/commons/models/broadcast.models';
import { CustomError } from 'src/app/commons/models/exception.model';
import { Inbox } from 'src/app/commons/models/inbox.models';
import { TIME_ZONES } from 'src/app/commons/constants/timezone.constants';

import * as moment from 'moment';


@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {
  @Input() inbox: Inbox = new Inbox();
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  @Output() eventDetails: EventEmitter<any> = new EventEmitter();

  private textMessage: ComposeText = new ComposeText();
  private phoneNumbers: PhoneNumbers[] = Array<PhoneNumbers>();

  public form: InviteForm;
  public user: User = new User();
  public error: CustomError = new CustomError();

  htmlToAdd: any;
  defaultTime: string;
  events = [];
  timezoneList = [];
  isSubmitting = false;
  viewEvent = false;

  constructor(
    private authService: AuthService,
    private inboxService: InboxService,
    private broadcastService: BroadcastService,
  ) {}

  ngOnInit() { 
    this.form = new InviteForm(new Invite());
    this.defaultTime = moment().format('YYYY-MM-DD')+'T00:00';
    this.timezoneList = TIME_ZONES;

    this.authService.user.subscribe(
      response => {
        this.user = new User(response);
      }
    );

    this.broadcastService.fetchPhoneNumber();
    this.broadcastService.phoneNumbers.subscribe((resp: PhoneNumbers[]) => {
      this.phoneNumbers = resp;
    });

    this.fetchEvents();
  }

  fetchEvents() {
    this.inboxService.getEvents().subscribe(res => {
      res.forEach(r => {
        if(this.inbox.prospect) {
          if(r.attendees[0]['email'] === this.inbox.prospect.email) {
            this.events.push(r);
          }
        } else {
          if(r.attendees[0]['displayName'] === this.inbox.thread_id) {
            this.events.push(r);
          }
        }
      });
    });
  }

  closeInviteModal() {
    this.closeModal.emit(true);
  }

  formSubmit({value, valid}: {value: Invite, valid: boolean}) {
    this.form.submitted = true;

    let prospect: any;
    let not_prospect: any;

    if(this.inbox.prospect) {
      prospect = {prospect: this.inbox.prospect.email_id};
      not_prospect = {not_prospect: ''};
    } else {
      not_prospect = {not_prospect: this.inbox.thread_id};
      prospect = '';
    }

    this.isSubmitting = true;
    this.error = new CustomError();

    if(valid) {
      if (new Date(value.start_date).getTime() < new Date(value.end_date).getTime()) {
        value.start_date = value.start_date + ':00.000';
        value.end_date = value.end_date + ':00.00';
        
        let startDate = new Date(value.start_date);
        let endDate = new Date(value.end_date);
        const isoStartDate = new Date(startDate.getTime()-new Date().getTimezoneOffset()*60*1000).toISOString().split(".")[0];
        const isoEndDate = new Date(endDate.getTime()-(new Date().getTimezoneOffset())*60*1000).toISOString().split(".")[0];
  
        value.start_date = isoStartDate;
        value.end_date = isoEndDate;
  
        let validatedContacts = [];
        let contactList = [];
        let contact: any;

        if(this.inbox.prospect) {
          const {email_id, contact_number, name} = this.inbox.prospect;

          validatedContacts.push({name, email_id,  formatted_num: contact_number});
          contact = [];
        } else {
          contactList.push({contact_number: this.inbox.thread_id});
          contact = {contact: contactList}
        }

        this.textMessage['number'] = this.phoneNumbers[0].phone_number;
  
        value['number'] = this.phoneNumbers[0].phone_number;
        value['service'] = this.textMessage.service;
        value['contact_list'] = [];
    
        const receiver = {receiver: validatedContacts}
        const data = {...value, ...prospect, ...not_prospect, ...contact, ...receiver};
        
        this.eventDetails.emit(data);
        this.closeInviteModal();
      } else {
        this.error['code'] = "invalid_date";
        this.error['message'] = "Invalid date: End date must be greater than start date."
        console.log('ERROR: ', this.error);
        this.isSubmitting = false;
      }
    }
  }
}
