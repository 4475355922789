import { NavComponent } from '../../components/partials/nav/nav.component';
import { SideComponent } from '../../components/partials/side/side.component';

/* CONTENT ONLY LAYOUT
 * @desc : web layout view that only loads the
 *         content fully. (no navs, footer, sidebar, etc..)
 */
export function ContentOnly(content) {
  return { content };
}


/* USER NAVIGATION, CONTENT
* @desc : web layout view that preloads the navigation bar
*         together with the content.
*/
export function NavContent(content, customNav?) {
  customNav = customNav ? customNav : NavComponent;
  return {
    nav: customNav,
    content
  };
}


/* USER NAVIGATION, SIDEBAR, CONTENT
 */
export function NavSideContent(content, customNav?, customSide?) {
  customNav = customNav ? customNav : NavComponent;
  customSide = customSide ? customSide : SideComponent;
  return {
    nav: customNav,
    side: customSide,
    content
  };
}
