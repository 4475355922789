import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CreateVoiceRecordForm } from 'src/app/commons/forms/voice.forms';
import { VoiceRecordingModel } from 'src/app/commons/models/voice.models';
import { VoiceService } from 'src/app/commons/services/voice.service';
import { WebhookService } from 'src/app/commons/services/websocket/webhook.service';

import phone from 'phone';

@Component({
  selector: 'app-via-phone',
  templateUrl: './via-phone.component.html',
  styleUrls: ['./via-phone.component.scss']
})
export class ViaPhoneComponent implements OnInit {
  @Output() closeViaPhoneModal: EventEmitter<any> = new EventEmitter();

  private connection;
  public form: CreateVoiceRecordForm;
  public isValid = false;
  public isProcessing = false;

  constructor(
    private viaPhoneModal: NgbActiveModal,
    private voiceService: VoiceService,
    private webhook: WebhookService,
  ) { }

  async ngOnInit() {
    this.form = new CreateVoiceRecordForm(new VoiceRecordingModel());
    await this.connect();
  }

  async connect() {
    this.connection = await this.webhook.connectToVoiceBroadcast();
    if (this.connection) {
      this.connection
      .pipe()
      .subscribe( (voiceRecord: any) => {
        this.voiceService.appendNewRecord(voiceRecord.data);
      });
    }
  }

  onSend({value, valid}: {value: VoiceRecordingModel, valid: boolean}) {
    this.isValid = valid;

    if (valid && this.verifyPhoneNumber(value)) {
      this.isProcessing = true;
      this.voiceService.initiateVoiceRecord(this.form.form.value)
      .then( (response) => {
        // this.isProcessing = false;
        this.openVoiceRecordCreateModal('selection');
      })
      .catch( errors => {
        console.log(errors);
      });
    }
  }

  verifyPhoneNumber( formData: VoiceRecordingModel): boolean {
    const recordingData = new VoiceRecordingModel(formData);
    const verifiedPhone =  phone(recordingData.phone);

    if (verifiedPhone.length) {
      this.form.form.patchValue({phone: verifiedPhone[0]});
      return this.isValid = true;
    }
    this.form.errors = recordingData.invalidMessage;
    return this.isValid = false;
  }

  openVoiceRecordCreateModal(type = 'create') {
    this.closeRecordViaPhoneModal();
    this.closeViaPhoneModal.emit(type);
  }

  closeRecordViaPhoneModal() {
    this.viaPhoneModal.dismiss();
  }


}
