import * as _ from 'lodash';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreaterecordsComponent } from './modals/createrecords/createrecords.component';
import { VoiceService } from 'src/app/commons/services/voice.service';
import { BroadcastVoiceMessageForm } from 'src/app/commons/forms/voice.forms';
import { SetOutboundCallModel } from 'src/app/commons/models/voice.models';
import { Prospect } from 'src/app/commons/models/prospects.models';

@Component({
  selector: 'app-voice',
  templateUrl: './voice.component.html',
  styleUrls: ['./voice.component.scss']
})
export class VoiceComponent implements OnInit {
  public hasRecording = false;
  public broadcastForm: BroadcastVoiceMessageForm;
  public selectedBroadcastType: any;
  public voiceRecordCreateModal: NgbModalRef;
  public soundRecordSelectionModal: NgbModalRef;
  public portal: NgbModalRef;
  public content: any;
  public options = {size: 'lg', centered: true};

  constructor(
    public state: StateService,
    public modalService: NgbModal,
    public voiceService: VoiceService,
  ) {
    this.broadcastForm = new BroadcastVoiceMessageForm();
  }

  ngOnInit() {
    this.voiceService.fetchVoiceRecords();
    this.broadcastForm = new BroadcastVoiceMessageForm();
  }



  openChildModal(type: any, content?: any) {
    if (type === 'selection') {
      this.openRecordSelectionModal(content, this.selectedBroadcastType);
    } else {
      this.portal = this.modalService.open(CreaterecordsComponent, this.options);
      this.portal.componentInstance.closeCreateModal.subscribe( (resp: any) => {
        this.openChildModal(resp, this.content);
      });
      this.closeModal({modalAction: 'selection'});
    }
  }

  // open for select voice records
  openRecordSelectionModal(content: any, formGroup?: any) {
    this.content = content; // store selection modal content
    this.soundRecordSelectionModal = this.modalService.open(content, this.options );
    this.selectedBroadcastType = formGroup;
  }

  closeModal(obj: any) {
    if (obj.modalAction === 'selection') {
      this.hasRecording = _.reduce(this.broadcastForm.form.value.type, (result, value: any) => {
        if (!!value.record_id) {
          return result = !!value.record_id;
        }
        return result;
      }, null);
      this.soundRecordSelectionModal.close();
    }
  }

  async onNext({value, valid}: {value: SetOutboundCallModel, valid: boolean}) {
    let selectedContacts = [];
    let isAutoOpen = false;

    this.voiceService.getCallContact
    .subscribe( response => {
      if (Object.keys(response).length) {
        Object.assign(value, response);
      }

      if (value.prospect) {
        selectedContacts = value.prospect.map( (item) => item.email_id);
        isAutoOpen = true;
      }

      this.voiceService.setOutBoundCallData(value);

      this.state.go(
        'broadcast-contacts',
        {
          prev: this.state.current.name,
          contactType: 'voice',
          contacts: selectedContacts,
          autoOpen: isAutoOpen
      });
    });

  }
}
