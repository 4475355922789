import { StateService } from '@uirouter/angular';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { CustomError } from './../../../commons/models/exception.model';

@Component({
  selector: 'app-credit-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() error: CustomError = new CustomError();
  @Input() inModal = false;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private state: StateService
  ) { }

  ngOnInit() {
  }

  redirect() {
    if (this.inModal) {this.closeModal.emit(true)};
    this.state.go('credit-amount');
  }

}
