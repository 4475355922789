import { ContentOnly, NavSideContent, NavContent } from 'src/app/commons/utils/layout.utils';
import { BaseComponent } from './base/base.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProspectsComponent } from './prospects/prospects.component';
import { InboxComponent } from './inbox/inbox.component';
import { ListComponent } from './inbox/list/list.component';
import { CreateComponent } from './inbox/create/create.component';
import { ComposeComponent } from './inbox/create/compose/compose.component';
import { ContactComponent } from './inbox/create/contact/contact.component';
import { BroadcastComponent } from './broadcast/broadcast.component';
import { LoginRequired } from 'src/app/commons/utils/security.utils';
import { BroadcastListComponent } from './broadcast/list/list.component';
import { BroadcastTextComponent } from './broadcast/text/text.component';
import { BroadcastTextOverviewComponent } from './broadcast/text/overview/overview.component';
import { RecordsComponent } from './broadcast/text/records/records.component';
import { MessageComponent } from './broadcast/text/message/message.component';
import { VoiceComponent } from './broadcast/voice/voice.component';
import { SoundComponent } from './broadcast/voice/sound/sound.component';


export const DASHBOARD_STATES: object[] = [
  {
    name: 'base-dashboard',
    url: '',
    views: ContentOnly(BaseComponent),
    redirectTo: 'dashboard',
  },
  {
    name: 'dashboard',
    url: '/dashboard',
    parent: 'base-dashboard',
    views: NavSideContent(DashboardComponent),
    onEnter: LoginRequired
  },
  {
    name: 'prospects',
    url: '/prospects',
    parent: 'base-dashboard',
    views: NavSideContent(ProspectsComponent),
    onEnter: LoginRequired

  },
  {
    name: 'inbox',
    url: '/inbox',
    parent: 'base-dashboard',
    views: NavSideContent(InboxComponent),
    redirectTo: 'inbox-main',
    onEnter: LoginRequired
  },
  {
    name: 'inbox-main',
    url: '',
    parent: 'inbox',
    views: ContentOnly(ListComponent),
    params: {
      id: ''
    },
    onEnter: LoginRequired
  },
  {
    name: 'inbox-create',
    url: '',
    parent: 'inbox',
    views: ContentOnly(CreateComponent),
    redirectTo: 'inbox-compose',
    params: {
      contactType: 'phone-number',
      contacts: [],
      autoOpen: false
    },
    onEnter: LoginRequired
  },
  {
    name: 'inbox-compose',
    url: '/compose',
    parent: 'inbox-create',
    views: ContentOnly(ComposeComponent),
    onEnter: LoginRequired
  },
  {
    name: 'inbox-contacts',
    url: '/contacts',
    parent: 'inbox-create',
    views: ContentOnly(ContactComponent),
    onEnter: LoginRequired
  },
  {
    name: 'broadcast',
    url: '/broadcast',
    parent: 'base-dashboard',
    views: NavSideContent(BroadcastComponent),
    redirectTo: 'broadcast-list',
    onEnter: LoginRequired
  },
  {
    name: 'broadcast-list',
    url: '/:type',
    params: {
      type: '',
      module: ''
    },
    parent: 'broadcast',
    views: ContentOnly(BroadcastListComponent),
    onEnter: LoginRequired
  },
  // VOICE MAIN PAGE
  {
    name: 'voice-create',
    url: '/voice',
    params: {
      module: 'voice',
    },
    parent: 'broadcast',
    views: ContentOnly(VoiceComponent),
    redirectTo: 'sound-selection',
    onEnter: LoginRequired
  },
  {
    name: 'sound-selection',
    url: '/sound',
    params: {
      module: 'voice',
      prev: ''
    },
    parent: 'voice-create',
    views: ContentOnly(SoundComponent),
    onEnter: LoginRequired
  },
  {
    name: 'broadcast-contacts',
    url: '/contact',
    params: {
      module: 'voice',
      prev: '',
      contactType: '',
      contacts: [],
      autoOpen: false
    },
    parent: 'voice-create',
    views: ContentOnly(ContactComponent),
    onEnter: LoginRequired
  },
  // END FOR VOICE MAIN PAGE
  {
    name: 'broadcast-detail',
    url: '/broadcast/:id',
    parent: 'broadcast',
    views: ContentOnly(BroadcastTextComponent),
    redirectTo: 'broadcast-text-overview',
    onEnter: LoginRequired
  },
  {
    name: 'broadcast-text-overview',
    url: '/overview',
    parent: 'broadcast-detail',
    views: ContentOnly(BroadcastTextOverviewComponent),
    onEnter: LoginRequired
  },
  {
    name: 'broadcast-text-message',
    url: '/message/:id',
    parent: 'broadcast-detail',
    views: ContentOnly(MessageComponent),
    onEnter: LoginRequired
  },

  {
    name: 'broadcast-text-record',
    url: '/records',
    parent: 'broadcast-detail',
    views: ContentOnly(RecordsComponent),
    onEnter: LoginRequired
  },
];


