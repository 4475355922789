import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';
import { BroadcastService } from 'src/app/commons/services/broadcast.service';
import { Broadcast, BroadcastMapMarker } from 'src/app/commons/models/broadcast.models';

@Component({
  selector: 'app-broadcast-text-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class BroadcastTextOverviewComponent implements OnInit {
  private broadcastID = null;
  public broadcast: Broadcast = new Broadcast();

  private map: google.maps.Map = null;
  private heatmap: google.maps.visualization.HeatmapLayer = null;

  public zoom = 2.3;
  // initial center position for the map
  public lat = 39.695065;
  public lng = -83.621511;
  public markers = [];
  public counter = {};
  private mapMarker: google.maps.Marker[] = [];

  textFilterOption = {
    sent: false,
    delivered: false,
    failed: false,
    undelivered: false
  };

  voiceFilterOption = {
    queued: false,
    initiated: false,
    answered: false,
    noAnswer: false,
    busy: false,
    completed: false
  };

  constructor(
    private broadcastService: BroadcastService,
    private state: StateService
  ) { }

  async ngOnInit() {
    this.broadcastID = this.state.params.id;
    await this.broadcastService.fetchBroadcast(this.broadcastID);

    // initial load
    await this.broadcastService.broadcastDetail.subscribe(resp => {
      this.broadcast = resp;
      if (this.broadcast.type === 'Text') {
        this.broadcastService.fetchBroadcastRecentActivity(this.broadcast.id);
      }
    });
  }


  get isTextType() {
    return this.broadcast.type === 'Text';
  }


  splitText(text) {
    return text.split(/(?=[A-Z])/).join(' ');
  }

  objectKeys(obj) {
    return  obj ? Object.keys(obj).filter(item => item !== 'total') : [];
  }

  setMap(mapInstance: google.maps.Map) {
    this.map = mapInstance;
    this.broadcastService.broadcastDetail.subscribe(resp => {
      this.broadcast = resp;
      this.setHeatMap(); // set map after the load
    });
  }

  setHeatMap() {
    if (this.heatmap) { this.setMapOnNullMarkers(); }
    if (this.map) {
      const coords: google.maps.LatLng[] = this.gmapLatLng;
      this.heatmap = new google.maps.visualization.HeatmapLayer({
        map: this.map,
        data: coords,
        dissipating: true,
        radius: 20
      });
      this.setMarkers();
    }
  }

  setMarkers() {
    this.markers.forEach( item => {
      const marker = new google.maps.Marker({
        position: item.pos,
        map: this.heatmap.getMap(),
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillOpacity: 1,
          strokeColor: '',
          strokeWeight: 0
        },
        label: {
          text: this.getMarkerLabel(item)
        }
      });
      this.mapMarker.push(marker);
    }); // end for
  }

  clearMarkers() {
    this.mapMarker.forEach( marker => {
      marker.setMap(null);
    });
  }

  setMapOnNullMarkers() {
    this.heatmap.setMap(null);
    this.clearMarkers();
  }

  get recipients() {
    return this.broadcast.recipients ? this.broadcast.recipients : [];
  }

  get gmapLatLng() {
    this.markers = [];
    this.counter = {};
    const coords: google.maps.LatLng[] = [];
    const rcpCoords = this.recipientsCoordinates;
    if (rcpCoords.length) {
      rcpCoords.map( (item: any) => {
        if (item) {
          const latitude = parseFloat(item.latitude);
          const longitude = parseFloat(item.longitude);
          coords.push(new google.maps.LatLng(latitude, longitude));
          const name = item.name.toLowerCase();
          this.counter[name] = (this.counter[name] || 0) + 1;
          this.markers.push({lat: latitude, lng: longitude, pos: new google.maps.LatLng(latitude, longitude), label: name});
        }
      });
    }
    return coords;
  }

  getMarkerLabel(m: any) {
    return this.counter[m.label].toString();
  }

  get recipientsCoordinates() {
    // filter recipients that is sent status
    const coords = this.recipients.filter( item => {
      const status = item.status ? item.status.toLowerCase() : null;
      const optionSet = this.isTextType ? this.textFilterOption : this.voiceFilterOption;
      // tslint:disable-next-line: max-line-length
      const allUncheck = Object.values(optionSet).every(val => !val); // initial state is filters uncheck
      if (allUncheck) {
        return allUncheck;
      }
      return optionSet[status];
    }).map( item => this.isTextType ?  item.receiver.location : item.callee.location);

    return coords;
  }
}
