import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProspectList, Contacts } from '../models/prospects.models';

import { BehaviorSubject } from 'rxjs';
import { queryparams } from '../utils/http.utils';
import { API_PROSPECTS, API_PROSPECTS_UPDATE, API_CONTACT_GROUP, API_CONTACTS } from '../constants/api.constants';

@Injectable({
  providedIn: 'root'
})
export class ProspectService {
  private prospects$: BehaviorSubject<ProspectList> = new BehaviorSubject(new ProspectList());
  private contacts$: BehaviorSubject<Contacts> = new BehaviorSubject(new Contacts());

  constructor(
    private http: HttpClient
  ) { }

  get prospects() {
    return this.prospects$.asObservable();
  }

  get contacts() {
    return this.contacts$.asObservable();
  }

  createContactList(data) {
    return this.http.post(API_CONTACT_GROUP, data)
      .toPromise();
  }

  fetchContacts(params?) {
    const response = this.http.get(API_CONTACTS, params)
      .toPromise()
      .then(resp => {
        this.contacts$.next(new Contacts(resp));
      });
  }

  getProspects(data?: object) {
    const params = data === undefined ? '' : queryparams(data);

    return this.http.get(API_PROSPECTS + params)
      .toPromise()
      .then((resp) => {
        this.prospects$.next(new ProspectList(resp));
      });
  }

  async updateProspects() {
    const response = await this.http.get(API_PROSPECTS_UPDATE)
      .toPromise();

    this.getProspects();

    return response;
  }
}

