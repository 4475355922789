import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';
import { Register } from 'src/app/commons/models/user.models';
import { RegistrationForm } from 'src/app/commons/forms/users.forms';
import { AuthService } from 'src/app/commons/services/auth/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public form: RegistrationForm = new RegistrationForm(new Register());
  public isSuccess = false;
  public isEmailExist = false;

  constructor(
    private auth: AuthService,
    private state: StateService
  ) { }

  ngOnInit() {
  }

  onSubmit({value, valid}: {value: Register, valid: boolean}) {
    this.form.submitted = true;

    if (valid) {
      this.isSuccess = false;
      this.isEmailExist = false;

      this.auth.register(value)
        .then((resp) => {
          this.isSuccess = true;
          setTimeout(() => {
            this.state.go('login');
          }, 2000);
        })
        .catch((err) => {
          console.log(err.error);
          this.isEmailExist = err.error.hasOwnProperty('email');
        });
    }
  }

}
