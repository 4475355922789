import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideComponent } from './side/side.component';
import { NavComponent } from './nav/nav.component';

import { UIRouterModule } from '@uirouter/angular';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SideComponent, NavComponent],
  imports: [
    CommonModule,
    UIRouterModule,
    ReactiveFormsModule,
    NgbModule
  ]
})
export class PartialsModule { }
