import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';


import { PurchaseComponent } from './purchase/purchase.component';
import { AmountComponent } from './purchase/amount/amount.component';
import { PhoneComponent } from './purchase/phone/phone.component';

import { BaseComponent } from './base/base.component';
import { ConfirmComponent } from './purchase/confirm/confirm.component';
import { ReceiptComponent } from './purchase/receipt/receipt.component';
import { CardComponent } from './card/card.component';
import { CardsComponent } from './cards/cards.component';
import { CreditComponent } from './credit/credit.component';
import { AlertComponent } from './alert/alert.component';




@NgModule({
  declarations: [
    PurchaseComponent,
    AmountComponent,
    BaseComponent,
    ConfirmComponent,
    ReceiptComponent,
    CardComponent,
    CardsComponent,
    CreditComponent,
    AlertComponent,
    PhoneComponent
  ],
  imports: [
    CommonModule,
    UIRouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  exports: [
    CreditComponent,
    AlertComponent
  ]
})
export class CreditModule { }
