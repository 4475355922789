export class CreditOrder {
  item: string = null;
  quantity = 0;
  price = 0.06;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class BillingAddress {
  first_name: string = null;
  last_name: string = null;
  extended_address: string = null;
  locality: string = null;
  country_code_alpha2: string = null;
  region: string = null;
  postal_code: string = null;
  phone: string = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class PaymentMethod {
  token: string = null;
  last_4: string = null;
  bin: string = null;
  card_type: string = null;
  cardholder_name: string = null;
  commercial: string = null;
  country_of_issuance: string = null;
  expiration_month: string = null;
  expiration_year: string = null;
  billing_address: BillingAddress = new BillingAddress();

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class PaymentMethods {
  cards: PaymentMethod[] = Array<PaymentMethod>();

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class Card {
  id: string = null;
  number: string = null;
  cvv2: string = null;
  last_4: string = null;
  expiration_month: string = null;
  expiration_year: string = null;
  card_type: string = null;
  expiration: string = null;
  expire_month: string = null;
  expire_year: string = null;
  billing_address: BillingAddress = new BillingAddress();

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class Credit {
  id: string = null;
  credits: number = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class CreditBalance {
  balance: number = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}


// model for the payment transaction return
export class Payment {
  amount: string = null;
  id: string = null;
  status: string = null;
  credit_card_details: Card = new Card();
  line_items: TransactionItem[] = Array<TransactionItem>();

  constructor(data = {}) {
    Object.assign(this, data);
  }
}



export class TransactionItem {
  commodity_code: string = null;
  description: string = null;
  discount_amount: string = null;
  kind: string = null;
  name: string = null;
  product_code: string = null;
  quantity: string = null;
  tax_amount: string = null;
  total_amount: string = null;
  unit_amount: string = null;
  unit_of_measure: string = null;
  unit_tax_amount: string = null;
  url: string = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class HostFieldsState {
  isEmpty = true;
  isFocused = false;
  isPotentiallyValid = false;
  isValid = false;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}


export class HostFieldsValidation {
  number: HostFieldsState = new HostFieldsState();
  expirationDate: HostFieldsState = new HostFieldsState();
  cvv: HostFieldsState = new HostFieldsState();

  constructor(data = {}) {
    Object.assign(this, data);
  }

  isFieldValid(f) {
    return this[f].isValid;
  }

  isComplete() {
    return !this.cvv.isEmpty && !this.expirationDate.isEmpty && !this.number.isEmpty;
  }

  isValid() {
    return this.cvv.isValid && this.expirationDate.isValid && this.number.isValid;
  }
}

