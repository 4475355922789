import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Inbox, InboxList, InboxThread, ComposeText } from '../models/inbox.models';
import { InviteEvent } from '../models/invite.models';
import { API_EMAIL_SEND, API_INBOX, API_INBOX_THREAD, API_TEXT_SEND, USER_PHONE_NUMBER, API_SCHEDULER } from '../constants/api.constants';
import { urlsafe, queryparams } from '../utils/http.utils';

@Injectable({
  providedIn: 'root'
})
export class InboxService {
  private inboxList$: BehaviorSubject<InboxList> = new BehaviorSubject(new InboxList());
  private inbox$: BehaviorSubject<Inbox> = new BehaviorSubject(new Inbox());
  private inboxThread$: BehaviorSubject<InboxThread> = new BehaviorSubject(new InboxThread());
  private textMessage$: BehaviorSubject<ComposeText> = new BehaviorSubject(new ComposeText());
  private eventList$: BehaviorSubject<InviteEvent> = new BehaviorSubject(new InviteEvent());

  public isFetchingThread = false;
  public isFetchingNewThread = false;

  public isFetchingInbox = false;
  constructor(
    private http: HttpClient
  ) { }


  sendEmail(data) {
    return this.http.post(API_EMAIL_SEND, data)
    .toPromise();
  }

  sendText(data) {
    return this.http.post(API_TEXT_SEND, data)
    .toPromise();
  }

  get inboxList() {
    return this.inboxList$.asObservable();
  }

  get inbox() {
    return this.inbox$.asObservable();
  }

  get inboxThread() {
    return this.inboxThread$.asObservable();
  }

  get textMessage() {
    return this.textMessage$.asObservable();
  }

  get eventList() {
    return this.eventList$.asObservable();
  }


  fetchInboxList() {
    return this.http.get(API_INBOX)
      .toPromise()
      .then((resp) => {
        this.inboxList$.next(new InboxList({list: resp}));
      });
  }

  fetchInbox(inboxId) {
    return this.http.get(urlsafe(API_INBOX, inboxId))
    .toPromise()
    .then((resp) => {
      this.isFetchingInbox = false;
      this.inbox$.next(new Inbox(resp));
    });
  }

  updateInbox(inbox) {
    this.inbox$.next(new Inbox(inbox));
  }

  updateInboxMessageIsRead(inboxId) {
    return this.http.post(urlsafe(API_INBOX, inboxId), {})
      .toPromise();
  }

  fetchThread(inboxId, params?: {}, reload?) {
    this.isFetchingThread = true;
    this.isFetchingNewThread = !!reload ? true : false;

    params = params ? queryparams(params) : '';
    return this.http.get(urlsafe(API_INBOX_THREAD, inboxId) + params)
      .toPromise()
      .then((resp) => {
        this.inboxThread$.next(new InboxThread(resp));
        this.isFetchingThread = false;
        this.isFetchingNewThread = false;

      });
  }

  updateTextMessage(data: ComposeText) {
    this.textMessage$.next(new ComposeText(data));
  }

  getEvents() {
    return this.http.get<InviteEvent[]>(API_SCHEDULER);
  }

  createEvent(data) {
    return this.http.post(API_SCHEDULER, data)
      .toPromise();
  }
}
