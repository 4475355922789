import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProspectService } from 'src/app/commons/services/prospect.service';
import { Contacts } from 'src/app/commons/models/prospects.models';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  public contacts: Contacts = new Contacts();

  constructor(
    private prospectService: ProspectService
  ) { }

  ngOnInit() {
    this.prospectService.fetchContacts();

    this.prospectService.contacts.subscribe(
      resp => {
        this.contacts = resp;
      }
    );
  }

  close() {
    this.closeModal.emit(true);
  }

}
