import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  lat = 39.965918;
  lng = -75.221833;
  zoom = 15;

  markers = [
    {
      lat: 39.965614,
      lng: -75.220621,
      label: 'A'
    },
    {
      lat: 39.959671,
      lng: -75.228448,
      label: 'B'
    },
    {
      lat: 39.965639,
      lng: -75.219386,
      label: 'C'
    },
    {
      lat: 39.962658,
      lng: -75.228688,
      label: 'D'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
