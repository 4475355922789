import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CreditOrder, CreditBalance, Payment, PaymentMethods, PaymentMethod } from '../models/credit.models';
import {
  API_BUY_CREDIT,
  API_CREDIT_BALANCE,
  API_PAYMENT_METHOD,
  API_PURCHASE} from '../constants/api.constants';
import { urlsafe } from '../utils/http.utils';

@Injectable({
  providedIn: 'root'
})
export class CreditService {
  public creditCards: PaymentMethods = new PaymentMethods();
  public isProcessing = false;

  private creditOrder$: BehaviorSubject<CreditOrder> = new BehaviorSubject(new CreditOrder());
  private creditCard$: BehaviorSubject<PaymentMethod> = new BehaviorSubject(new PaymentMethod());
  private creditBalance$: BehaviorSubject<CreditBalance> = new BehaviorSubject(new CreditBalance());
  private payment$: BehaviorSubject<Payment> = new BehaviorSubject(new Payment());


  constructor(
    private http: HttpClient
  ) { }

  get creditOrder() {
    return this.creditOrder$.asObservable();
  }

  get creditCard() {
    return this.creditCard$.asObservable();
  }

  get creditBalance() {
    return this.creditBalance$.asObservable();
  }

  get payment() {
    return this.payment$.asObservable();
  }


  updateCreditOrder(data) {
    this.creditOrder$.next(new CreditOrder(data));
  }

  updatePayment(data) {
    this.payment$.next(new Payment(data));
  }

  setLocalData(data: any, key: string): void {
    (window as any).localStorage[key] = JSON.stringify(data);
  }

  getLocalData(key: string) {
    return (window as any).localStorage[key];
  }

  rmLocalData(key: string) {
    if (!!this.getLocalData(key)) {
      (window as any).localStorage.removeItem(key);
    }
  }

  getCard(token) {
    return this.http.get(urlsafe(API_PAYMENT_METHOD, token))
    .toPromise()
    .then((resp) => {
      this.creditCard$.next(new PaymentMethod(resp));
    });
  }

  getCards() {
    this.isProcessing = true;
    return this.http.get(API_PAYMENT_METHOD)
    .toPromise()
    .then((resp) => {
      this.creditCards = new PaymentMethods({cards: resp});
      this.isProcessing = false;
      if (this.creditCards.cards.length) {
        const card = this.creditCards.cards[0];
        this.getCard(card.token);
      }
    });
  }

  buy_credit(data) {
    return this.http.post(API_BUY_CREDIT, data)
    .toPromise();
  }

  getCreditBalance() {
    return this.http.get(API_CREDIT_BALANCE)
    .toPromise()
    .then((resp) => {
      this.creditBalance$.next(new CreditBalance(resp));
    });
  }

  makePayment(data) {
    return this.http.post(API_PURCHASE, data)
    .toPromise();
  }


  // this function send the nonce to the server to create a payment method
  createPaymentMethod(data) {
    const response =  this.http.post(API_PAYMENT_METHOD, data)
    .toPromise();

    response.then(resp => {
      this.getCards();
      this.getCard(resp['payment_method_token']);
    });

    return response;
  }
}
