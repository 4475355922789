import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { BaseComponent } from "./base/base.component";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxPaginationModule } from "ngx-pagination";
import { ReactiveFormsModule } from "@angular/forms";
import { UIRouterModule } from "@uirouter/angular";
import { CreditModule } from "../credit/credit.module";

import { AgmCoreModule } from "@agm/core";

import { GOOGLE_API_KEY } from "src/app/commons/constants/conf.constants";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { ProspectsComponent } from "./prospects/prospects.component";
import { InboxComponent } from "./inbox/inbox.component";
import { ListComponent } from "./inbox/list/list.component";
import { CreateComponent } from "./inbox/create/create.component";
import { ComposeComponent } from "./inbox/create/compose/compose.component";
import { ContactComponent } from "./inbox/create/contact/contact.component";
import { BroadcastComponent } from "./broadcast/broadcast.component";
import { EmailComponent } from "./inbox/email/email.component";
import { MapComponent } from "./broadcast/map/map.component";
import { ConversationComponent } from "./inbox/conversation/conversation.component";
import { ConversationMessageComponent } from "./inbox/conversation/message/message.component";
import { TextComponent } from "./inbox/text/text.component";
import { BroadcastListComponent } from "./broadcast/list/list.component";
import { BroadcastTextComponent } from "./broadcast/text/text.component";
import { BroadcastTextOverviewComponent } from "./broadcast/text/overview/overview.component";
import { RecordsComponent } from "./broadcast/text/records/records.component";
import { MessageComponent } from "./broadcast/text/message/message.component";
import { CopyComponent } from "./inbox/create/contact/copy/copy.component";
import { SelectComponent } from "./inbox/create/contact/select/select.component";

// voice components
import { VoiceComponent } from "./broadcast/voice/voice.component";
import { SelectrecordsComponent } from "./broadcast/voice/modals/selectrecords/selectrecords.component";
import { CreaterecordsComponent } from "./broadcast/voice/modals/createrecords/createrecords.component";
import { ViaPhoneComponent } from "./broadcast/voice/modals/createrecords/via-phone/via-phone.component";
import { SoundComponent } from "./broadcast/voice/sound/sound.component";
import { MaincontactcomponentComponent } from "./partials/maincontactcomponent/maincontactcomponent.component";
import { BroadcastStatusComponent } from "./broadcast/text/overview/broadcast-status/broadcast-status.component";
import { InviteComponent } from "./inbox/invite/invite.component";
import { ConfirmComponent } from "./inbox/invite/confirm/confirm.component";
import { DirectivesModule } from "src/app/commons/directives/directives.module";
import { DeleteconfirmComponent } from "./broadcast/voice/modals/selectrecords/deleteconfirm/deleteconfirm.component";

@NgModule({
  declarations: [
    BaseComponent,
    DashboardComponent,
    ProspectsComponent,
    InboxComponent,
    ListComponent,
    CreateComponent,
    ComposeComponent,
    ContactComponent,
    BroadcastComponent,
    EmailComponent,
    BroadcastTextOverviewComponent,
    MapComponent,
    ConversationComponent,
    TextComponent,
    BroadcastListComponent,
    BroadcastTextComponent,
    RecordsComponent,
    MessageComponent,
    CopyComponent,
    SelectComponent,
    SelectrecordsComponent,
    CreaterecordsComponent,
    VoiceComponent,
    ViaPhoneComponent,
    SoundComponent,
    MaincontactcomponentComponent,
    BroadcastStatusComponent,
    InviteComponent,
    ConfirmComponent,
    ConversationMessageComponent,
    DeleteconfirmComponent,
  ],
  imports: [
    CommonModule,
    UIRouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CreditModule,
    AgmCoreModule.forRoot({
      apiKey: GOOGLE_API_KEY,
      libraries: ["places", "visualization"],
    }),
    NgxPaginationModule,
    DirectivesModule,
  ],
  entryComponents: [
    CreaterecordsComponent,
    ViaPhoneComponent,
    DeleteconfirmComponent,
  ],
  exports: [CreaterecordsComponent, ViaPhoneComponent],
})
export class DashboardModule {}
