import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/commons/services/auth/auth.service';
import { User } from 'src/app/commons/models/user.models';
import { ProspectService } from 'src/app/commons/services/prospect.service';
import { ProspectList, ProspectFilter } from 'src/app/commons/models/prospects.models';
import { InboxService } from 'src/app/commons/services/inbox.service';
import { Inbox, InboxList } from 'src/app/commons/models/inbox.models';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public user: User = new User();

  private $prospects: ProspectList = new ProspectList();
  private $inbox: InboxList = new InboxList();

  constructor(
    private prospectService: ProspectService,
    private inboxService: InboxService,
    private authService: AuthService
  ) { }

  async ngOnInit() {
    this.fetchProspects();
    this.fetchInbox();
    this.authService.user.subscribe(
      resp => {
        this.user = new User(resp);
      }
    );
  }

  get prospects() {
    return this.$prospects;
  }

  get inbox() {
    return this.$inbox.list.length;
  }



  async fetchProspects() {
    await this.prospectService.getProspects();
    await this.loadProspects();
  }

  async fetchInbox() {
    await this.inboxService.fetchInboxList();
    await this.loadInbox();
  }


  loadInbox() {
    this.inboxService.inboxList.subscribe((resp: InboxList) => {
      this.$inbox = new InboxList(resp);
    });
  }

  loadProspects() {
    this.prospectService.prospects.subscribe(
      resp => {
        this.$prospects = new ProspectList(resp);
      }
    );
  }

}
