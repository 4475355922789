import { CreditBalance } from './../../../commons/models/credit.models';
import { CreditService } from 'src/app/commons/services/credit.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit',
  templateUrl: './credit.component.html',
  styleUrls: ['./credit.component.scss']
})
export class CreditComponent implements OnInit {
  public balance: CreditBalance = new CreditBalance();

  constructor(
    private creditService: CreditService
  ) { }

  ngOnInit() {
    this.creditService.getCreditBalance();
    this.updateBalance();
  }

  updateBalance() {
    this.creditService.creditBalance.subscribe((resp) => {
      this.balance = resp;
    });
  }

}
