import { Component, OnInit } from "@angular/core";
import { VoiceComponent } from "../voice.component";

import { VoiceRecordsModel } from "src/app/commons/models/voice.models";

@Component({
  selector: "app-sound",
  templateUrl: "./sound.component.html",
  styleUrls: ["./sound.component.scss"],
})
export class SoundComponent extends VoiceComponent implements OnInit {
  private voiceRecords$: VoiceRecordsModel[] = Array<VoiceRecordsModel>();

  ngOnInit() {
    this.fetchVoiceRecords();
    console.log("select a sound...");
  }

  get voiceRecords() {
    return this.voiceRecords$;
  }

  fetchVoiceRecords() {
    this.voiceService.getVoiceRecords.subscribe(
      (records: VoiceRecordsModel[]) => {
        this.voiceRecords$ = records;
      }
    );
  }

  getRecord(item) {
    return this.voiceRecords.find((record) => record.id === item);
  }

  removeRecord(index) {
    const type = this.broadcastForm.form.value.type;
    type[index].record_id = null;
    this.broadcastForm.form.patchValue({ type });
  }
}
