import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';

import { BroadcastType } from 'src/app/commons/models/voice.models';

export class CreateVoiceRecordForm {
  public form: FormGroup;
  public errors: string = null;
  public submitted = false;

  constructor(data) {
    // Initialize the form builder
    this.form = new FormBuilder().group({
      phone: new FormControl(null, [Validators.required]),
      service: new FormControl(2)
    });
  }

  // check form if all field is valid
  get isValid() {
    return this.form.valid;
  }

  // check if form field is valid
  valid(f: any) {
    return (!this.form.get(f).valid && this.form.get(f).touched);
  }

  updateValue(f, value) {
    this.form.get(f).setValue(value);
  }
}

export class BroadcastVoiceMessageForm {
  public form: FormGroup;
  public errors: string = null;
  public submitted = false;

  constructor(data?) {
    // Initialize the form builder
    this.form = new FormBuilder().group({
      service: new FormControl(2),
      record_id: new FormControl(null, [Validators.required]),
      type: this.createBroadcastType(),
      prospect: new FormControl(null, [])
    });
  }


  // check form if all field is valid
  get isValid() {
    return this.form.valid;
  }

  // check if form field is valid
  valid(f: any) {
    return (!this.form.get(f).valid && this.form.get(f).touched);
  }

  updateValue(f, value) {
    this.form.get(f).setValue(value);
  }

  createBroadcastType() {
    /**
     * 1 = live answer
     * 2 = answering machine
     * 3 = do not call
     */
    const broadcastTypes = [
      {
        type: 1,
        label: 'Live Answer'
      },
      // {
      //   type: 2,
      //   label: 'Answering Machine'
      // },
      // {
      //   type: 3,
      //   label: 'Do Not Call'
      // },
    ];

    const broadcastForms = broadcastTypes.map(item => {
      return new FormBuilder().group({
        type: new FormControl(item.type),
        label_name: new FormControl(item.label),
        toggle: new FormControl(true, [Validators.required]),
        record_id: new FormControl(null, [Validators.required])
      });
    });
    return new FormArray(broadcastForms);
  }
}
