import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { AuthService } from '../../../commons/services/auth/auth.service';
import { Login } from 'src/app/commons/models/login.models';
import { LoginForm } from 'src/app/commons/forms/users.forms';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private _form: LoginForm;

  constructor(
    private auth: AuthService,
    private state: StateService
  ) { }

  ngOnInit() {
    // initialize login form.
    this._form = new LoginForm(new Login());
  }

  get form() {
    return this._form;
  }

  onSubmit({value, valid}: {value: Login, valid: boolean}) {
    this.form.submitted = true;
    if (valid) {
      // send the data to the backend server
      this.auth.login(value)
        .then((resp: any) => {
          if (!!resp.auth_url) {
            this.redirectGoogleAuth(resp.auth_url);
          } else {
            this.state.go('dashboard');
          }
        })
        .catch((err: any) => {
          if (err.error.code === 'authorization') {
            this.form.errors = err.error.message;
          }
        })
      ;
    }
  }

  redirectGoogleAuth(url) {
    window.open(url, "_self");
  }

}
