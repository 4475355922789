// tslint:disable: variable-name
import { Prospect, ContactDetail} from './prospects.models';

export class ComposeEmail {
  service = 0;
  subject: string = null;
  body: string = null;
  prospect: Array<[]> = [];

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
export class ComposeText {
  number: string = null;
  service = 1;
  body: string = null;
  prospect: Array<[]> = [];
  contacts: any = null;
  contact: Array<[]> = [];

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class Message {
  id: string = null;
  subject: string = null;
  message_body: string = null;
  status: string = null;
  date_created: string = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class Inbox {
  id: string = null;
  service_type: string = null;
  prospect: Prospect = new Prospect();
  contact: ContactDetail = new ContactDetail();
  thread_id: string = null;
  new_messages: number = null;
  latest_message: Message = new Message();
  root_message: Message = new Message();


  constructor(data = {}) {
    Object.assign(this, data);

  }
}

export class InboxList {
  list: Inbox[] = Array<Inbox>();

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class InboxThread {
  count: number = null;
  next: string = null;
  previous: string = null;
  results: Message[] = Array<Message>();

  constructor(data = {}) {
    Object.assign(this, data);
  }
}


export class ContactList {
  name: string = null;
  contacts = [];

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
