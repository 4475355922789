import { StateService } from '@uirouter/angular';
import { CreditService } from './../../../../commons/services/credit.service';
import { Component, OnInit } from '@angular/core';
import { CREDIT_PRICE } from 'src/app/commons/constants/conf.constants';
import { CreditOrder } from 'src/app/commons/models/credit.models';

@Component({
  selector: 'app-amount',
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss']
})
export class AmountComponent implements OnInit {

  public customAmount = null;
  public isSelect = false;

  private creditQuantity = 0;

  creditOptions = [
    {credit: 500, price: 30, isSelected: false},
    {credit: 1000, price: 60, isSelected: false},
    {credit: 2500, price: 150, isSelected: false},
    {credit: 5000, price: 300, isSelected: false},
    {credit: 10000, price: 600, isSelected: false},
    {credit: 25000, price: 1500, isSelected: false},
    {credit: 50000, price: 3000, isSelected: false},
    {credit: 100000, price: 6000, isSelected: false},
  ];

  constructor(
    private creditService: CreditService,
    private stateService: StateService
  ) { }

  get customOrderTotal() {
    return this.customAmount * CREDIT_PRICE;
  }

  ngOnInit() {
    // this.creditService.getCard();
    this.creditService.getCards();
    this.creditService.rmLocalData('list_items');
    this.creditService.rmLocalData('phone_numbers');

  }

  updateCustomAmount(event) {
    this.creditOptions.forEach((item, i) => {
      item.isSelected = false;
    });
    this.isSelect = false;
    this.customAmount = event.target.value;
    this.creditQuantity = this.customAmount;
  }

  updateOrder(quantity) {
    this.creditService.setLocalData(quantity, 'credit_quantity');
  }

  selectedOption(index) {
    const quantitySelected = this.creditOptions[index].credit;

    this.creditOptions.forEach((item, i) => {
      item.isSelected = i === index ? true : false;
    });
    this.customAmount = null;
    this.isSelect = true;
    this.creditQuantity = quantitySelected;
  }

  saveItem() {
    const item = [{
      item_type: 'credit',
      name: `${this.creditQuantity} Credits`,
      quantity: this.creditQuantity
    }];
    this.creditService.setLocalData(item, 'list_items');
  }

  purchaseCredit() {
    this.saveItem();

    this.stateService.go('credit-confirm', {returnPage: 'credit-amount'});
  }
}
