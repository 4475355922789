import {
    FormBuilder, 
    FormGroup,
    FormControl,
    Validators
} from '@angular/forms';

export class InviteForm {
    public form: FormGroup;
    public errors: string = null;
    public submitted = false;

    constructor(data) {
        this.form = new FormBuilder().group({
            event_name: new FormControl(null, [Validators.required]),
            location: new FormControl(null, [Validators.required]),
            description: new FormControl(null, [Validators.required]),
            start_date: new FormControl(null, [Validators.required]),
            end_date: new FormControl(null, [Validators.required]),
            timezone: new FormControl(null, [Validators.required]),
        });
    }

    get isValid() {
        return this.form.valid;
    }

    valid(f: any) {
        return !(!this.form.get(f).valid && this.form.get(f).touched);
    }

    updateValue(f, value) {
        this.form.get(f).setValue(value);
    }

    hasError(f: any, e: any) {
        return this.form.get(f).hasError(e);
    }

    getFieldForm(f: string) {
        return this.form.get(f);
    }
}