import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';
import { BroadcastService } from 'src/app/commons/services/broadcast.service';
import { BroadcastMessageList, BroadcastListParams } from 'src/app/commons/models/broadcast.models';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss']
})
export class RecordsComponent implements OnInit {
  public messages: BroadcastMessageList = new BroadcastMessageList();
  public currPage = 1;
  public listParams: BroadcastListParams = new BroadcastListParams();

  public calendarModel: NgbDateStruct;
  
  constructor(
    private broadcastService: BroadcastService,
    private state: StateService
  ) { }

  ngOnInit() {
    this.fetchMessages();
    this.broadcastService.broadcastMessages.subscribe(resp => {
        this.messages = resp;
    });
  }

  get dateFormatted() {
    const date = this.calendarModel;

    if (date) {
      return `${date.year}-${('0' + date.month).slice(-2)}-${('0' + date.day).slice(-2)}`;
    }
    return null;
  }

  allDate() {
    this.calendarModel = null;
    this.listParams.date = null;
    this.fetchMessages();
  }

  dateSelect() {
    this.listParams.date = this.dateFormatted;
    this.fetchMessages();
  }

  fetchMessages() {
    this.broadcastService.fetchBroadcastMessageList(this.state.params.id, this.listParams.cleanedData());
  }

  pageChanged(newPage: any) {
    const param = {page: newPage};
    this.currPage = newPage;

    this.broadcastService.fetchBroadcastMessageList(this.state.params.id, param);
  }

}
