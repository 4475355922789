import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ContactList } from 'src/app/commons/models/inbox.models';

import phone from 'phone';
@Component({
  selector: 'app-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss']
})
export class CopyComponent implements OnInit {
  public isPasteEditMode = true;
  public pasteContacts: string = null;
  public newContactList: ContactList = new ContactList();

  @Output() continue: EventEmitter<ContactList> = new EventEmitter();
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  extractContacts() {
    const list = this.pasteContacts.split(/[,,\n]+/);
    let checkedNumber: Array<any> = [];
    this.newContactList.contacts = list.map( (contactNum: string) => {
      contactNum = contactNum.replace(/[ \t\r]/g, '');
      checkedNumber = phone(contactNum);
      if (checkedNumber.length) {
        return checkedNumber[0];
      }
    }).filter((item) => item );
    // this.isPasteEditMode = false;
  }

  onPaste() {
    setTimeout(() => {
      this.extractContacts();
    }, 200);
  }
  onContinue() {
    this.continue.emit(this.newContactList);
  }

  onClose() {
    this.closeModal.emit(true);
  }
}
