import { Component, OnInit, Output, EventEmitter  } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ViaPhoneComponent } from './via-phone/via-phone.component';

@Component({
  selector: 'app-createrecords',
  templateUrl: './createrecords.component.html',
  styleUrls: ['./createrecords.component.scss']
})
export class CreaterecordsComponent implements OnInit {
  @Output() closeCreateModal: EventEmitter<any> = new EventEmitter();
  private portal: any;

  constructor(
    private modalService: NgbModal,
    private createRecordModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }

  closeRecordModal() {
    this.createRecordModal.dismiss();
  }

  openSelectionModal() {
    this.closeRecordModal();
    this.closeCreateModal.emit('selection');
  }

  dialInAction() {

  }

  viaPhoneAction() {
    this.portal = this.modalService.open(ViaPhoneComponent, {size: 'lg', centered: true});
    this.portal.componentInstance.closeViaPhoneModal.subscribe( (resp: any) => {
      this.closeCreateModal.emit(resp);
    });
    this.closeRecordModal();
  }

  textToSpeechAction() {

  }
}
