import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';

import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import { CREDIT_PRICE, PHONE_NUMBER_PRICE } from 'src/app/commons/constants/conf.constants';
import { CreditService } from 'src/app/commons/services/credit.service';
import { CreditOrder, Payment, PaymentMethod } from 'src/app/commons/models/credit.models';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  public card: PaymentMethod = new PaymentMethod();
  public order: CreditOrder = new CreditOrder();
  public isError = false;
  public errorMessage: string = null;
  public isProcessing = false;
  public additionalCredit = 0;
  public isEditMode = false;
  public cart = [];

  private cardModal: NgbModalRef;
  private returnPage = null;

  creditOptions = [
    500,
    1000,
    2500,
    5000,
    10000,
    25000,
    50000,
    10000
  ];

  constructor(
    private modalService: NgbModal,
    private creditService: CreditService,
    private state: StateService
  ) {}

  ngOnInit() {
    this.returnPage = this.state.params.returnPage;
    // this.state.params

    this.creditService.getCards();
    this.updateOrder();
    this.updateCard();
    this.getListItems();
  }

  get creditValue() {
    return CREDIT_PRICE; /** need to fetch the credit value from the db */
  }

  get phoneNumberPrice() {
    return PHONE_NUMBER_PRICE; /** need to fetch the credit value from the db */
  }

  get isCreditType() {
    return this.returnUrl === 'credit-amount';
  }

  get phoneNumbers() {
    const phoneNumbers: any = this.creditService.getLocalData('phone_numbers');
    if (!!phoneNumbers) {
      return JSON.parse(phoneNumbers);
    } else {
      return [];
    }
  }

  get returnUrl() {
    return !!this.returnPage ? this.returnPage : 'dashboard';
  }

  get paymentMethodToken() {
    return this.card.token;
  }

  get totalAmount() {
    return this.cart.reduce((acc, curr) => {
      const price = this.getPrice(curr.item_type);
      const totalPrice = price * curr.quantity;
      return acc + totalPrice;
    }, 0);
  }

  addCredit() {
    const item = [{
      item_type: 'credit',
      name: `${this.additionalCredit} credits`,
      quantity: this.additionalCredit
    }];
    this.cart = this.cart.concat(item);
    this.isEditMode = false;
  }

  getListItems() {
    let items: any =  this.creditService.getLocalData('list_items');
    items = JSON.parse(items);
    this.cart = this.cart.concat(items);
  }

  getPrice(itemType) {
    if (itemType === 'phone_number') {
      return this.phoneNumberPrice;
    } else if (itemType === 'credit') {
      return this.creditValue;
    }
  }

  updateOrder() {
    this.creditService.creditOrder.subscribe(
    resp => {
        this.order = new CreditOrder(resp);
    }
    );
  }

  updateCard() {
    this.creditService.creditCard.subscribe(
      resp => {
          this.card = new PaymentMethod(resp);
      });
  }

  makePurchase() {
    this.isProcessing = true;
    const dataFormatted = {
      token: this.paymentMethodToken,
      list_items: this.cart,
      phone_numbers: this.phoneNumbers
    };

    this.creditService.makePayment(dataFormatted)
    .then(resp => {
      const payment = new Payment(resp);
      this.creditService.updatePayment(payment);
      this.isProcessing = false;
      this.state.go('credit-receipt');
    })
    .catch(err => {
      const errCode = err.error.code;
      if (errCode === 21404) {
        this.errorMessage = err.error.message;
      } else if (errCode === 21422) {
        this.errorMessage = `numbers ${err.error.message} is not available`;
      } else if (errCode === 'payment error') {
        this.errorMessage = 'Unsuccessful payment. Please try again.';
      }
      this.isProcessing = false;
      this.isError = true;
    });
  }

  open(content) {
    this.cardModal = this.modalService.open(content, {size: 'lg'});
  }

  closeModal(event) {
    this.cardModal.close();
  }
}
