import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomError } from 'src/app/commons/models/exception.model';

import { InboxService } from 'src/app/commons/services/inbox.service';
import { ReplyTextForm } from 'src/app/commons/forms/inbox.forms';
import { ComposeText } from 'src/app/commons/models/inbox.models';
import { CreditService } from 'src/app/commons/services/credit.service';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {
  @Input() contacts?: any = null;
  @Input() inbox: any = null;
  @Input() isReply = false;
  @Output() newMessage: EventEmitter<any> = new EventEmitter();

  public form: ReplyTextForm;
  public error: CustomError = new CustomError();
  isSent = false;
  isProcessing = false;

  constructor(
    private inboxService: InboxService,
    private creditService: CreditService
  ) { }

  ngOnInit() {
    this.form = new ReplyTextForm(new ComposeText());
  }

  addRepliedMessage(message: any) {
    this.newMessage.emit(message);
  }

  onSend({value, valid}: {value: ComposeText, valid: boolean}) {
    if (valid) {
      const prospectArray  = [];
      const contactArray = [];

      if (this.contacts.email_id) {
        const contact_number = this.contacts.contact.contact_number;
        prospectArray.push({email_id: this.contacts.email_id, formatted_num: contact_number});
      } else {
        contactArray.push(this.contacts.id);
      }

      const payload = {
        body: value.body,
        prospect: prospectArray,
        contact: contactArray,
        service: 1
      };

      this.isProcessing = true;
      this.inboxService.sendText(payload)
      .then(
        (resp: any) => {
          this.form.form.reset();
          this.addRepliedMessage(resp.threads);
          this.creditService.getCreditBalance();
          setTimeout( () => {
            this.isProcessing = false;
            this.isSent = false;
          }, 500);
        }
      ).catch(
        err => {
          const code = err.error.code;
          this.isProcessing = false;
          this.isSent = false;
          if (code === 'credits') {
            this.error = new CustomError(err.error);
          }
        }
      );
    }
  }

}
