import * as _ from 'lodash';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { InboxService } from 'src/app/commons/services/inbox.service';
import { InboxThread, Inbox, Message } from 'src/app/commons/models/inbox.models';
import { User } from 'src/app/commons/models/user.models';
import { AuthService } from 'src/app/commons/services/auth/auth.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WebhookService } from 'src/app/commons/services/websocket/webhook.service';
import { VoiceService } from 'src/app/commons/services/voice.service';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit {
  @Output() newMessage: EventEmitter<any> = new EventEmitter();
  @ViewChild('confirmModal', {static: false}) confirmModal: ElementRef;

  public connection;
  public user: User = new User();
  public inboxThread: InboxThread = new InboxThread();
  public conversation = [];
  public inbox: Inbox = new Inbox();
  public inviteDetails: any;
  public endMessage: Message = new Message();

  private emailModal: NgbModalRef;
  private textModal: NgbModalRef;
  private inviteModal: NgbModalRef;
  private confirmInviteModal: NgbModalRef;
  private callModal: NgbModalRef;

  constructor(
    public state: StateService,
    private inboxService: InboxService,
    private modalService: NgbModal,
    private authService: AuthService,
    private webhook: WebhookService,
    public voiceService: VoiceService,
  ) { }

  async ngOnInit() {
    this.fetchUser();
    this.getInbox();
    this.getInboxThread();
    await this.connect();
  }

  async connect() {
    this.connection = await this.webhook.connect();

    if (this.connection) {
      this.connection
      .pipe()
      .subscribe( (msg: any) => {
        const inboxId = this.inbox.id;

        if(inboxId === msg.data.inbox) {
          this.conversation = this.conversation.concat([msg.data]);
          this.inboxService.updateInboxMessageIsRead(inboxId);
        } else {
          this.newMessage.emit(msg.data.inbox);
        }
      });
    }
  }

  get conversationCount() {
    return this.conversation.length;
  }

  get isFetchThread() {
    return this.inboxService.isFetchingThread;
  }

  get isFetchNewThread() {
    return this.inboxService.isFetchingNewThread;
  }

  get isFetchInbox() {
    return this.inboxService.isFetchingInbox;
  }

  get contact() {
    return [this.inbox.prospect];
  }

  get contactNumber() {
    const {contact, prospect} = this.inbox;
    if (contact) {
      return contact.contact_number;
    } else {
      return prospect.contact ? prospect.contact.contact_number : prospect.contact_number;
    }

    return null;
  }

  get threadSubject() {
    return 'Re:'.concat(this.inbox.root_message.subject);
  }

  callThisProspect(inbox: any) {
    this.voiceService.setCallContact(inbox);
    this.state.go('voice-create', {module: 'voice'});

  }

  checkInboxProspect(inbox: any) {
    return inbox.prospect ? inbox.prospect : inbox.contact;
  }

  fetchUser() {
    this.authService.user.subscribe(
      resp => {
        this.user = new User(resp);
      });
  }

  getInbox() {
    this.inboxService.inbox.subscribe((resp: Inbox) => {
      this.inbox = resp;
    });
  }

  getInboxThread() {
    this.inboxService.inboxThread.subscribe((resp) => {
      const messages = resp.results.reverse();
      if (!resp.previous) {
        this.conversation = messages;
        
      } else {
        this.conversation = [...messages, ...this.conversation];
      }

      this.endMessage = new Message(messages[messages.length - 1]);
      this.inboxThread = resp;
    });
  }

  fetchPrevConvo() {
    if (this.inboxThread.next) {
      const page = new URL(this.inboxThread.next).searchParams.get('page');
      this.inboxService.fetchThread(this.inbox.id, {page}, false);
    }
  }

  addNewMessage($event) {
    this.conversation = this.conversation.concat($event);
  }
  
  compareDate(date, i) {
    if (i) {
      const prevDate = new Date(this.conversation[i-1].date_created).setHours(0,0,0,0);
      if (prevDate !== new Date(date).setHours(0,0,0,0)) {
        return true
      }
    }
    return false;
  }

  strToDate(date) {
    return new Date(date);
  }

  appendMessage(message) {
    this.conversation.push(message);
    this.closeConfirm();
  }

  isMe(id) {
    return this.user.id === id;
  }

  openEmail(content: any) {
    this.emailModal = this.modalService.open(content, {size: 'lg'});
  }

  openText(content: any ) {
    this.textModal = this.modalService.open(content, {size: 'lg'});
  }

  openInvite(content: any) {
    this.inviteModal = this.modalService.open(content, {size: 'lg'});
  }

  openConfirmInvite() {
    this.confirmInviteModal = this.modalService.open(this.confirmModal, {size: 'lg'});
  }

  openCall(content: any) {
    this.callModal = this.modalService.open(content);
  }

  closeText() {
    this.textModal.close();
  }

  closeEmail() {
    this.emailModal.close();
  }

  closeInvite() {
    this.inviteModal.close();
  }

  closeConfirm() {
    this.confirmInviteModal.close();
  }

  getInviteDetails(details: any) {
    this.inviteDetails = details;
    this.openConfirmInvite();
  }

  get latestMessageDate() {
    if (this.conversation.length) {
      return _.last(this.conversation).date_created;
    }
    return '';
  }

  prospectName(inbox) {
    return inbox.prospect ? inbox.prospect.name : inbox.thread_id;
  }

  senderName(inbox, message) {
    if (message.outbound) {
      return `${message.outbound.first_name} ${message.outbound.last_name}`;
    } else {
      return this.prospectName(inbox);
    }
  }
}
