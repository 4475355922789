import {
    FormBuilder,
    FormGroup,
    FormControl,
    Validators
} from '@angular/forms';

export class LoginForm {
  public form: FormGroup;
  public errors: string = null;
  public submitted = false;

  constructor(data) {
    // Initialize the form builder
    this.form = new FormBuilder().group({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(5)])
    });
  }

  // check form if all field is valid
  get isValid() {
    return this.form.valid;
  }

  // check if form field is valid
  valid(f: any) {
    return !(!this.form.get(f).valid && this.form.get(f).touched);
  }

  // check if the form field has an error
  hasError(f: any, e: any) {
    return this.form.get(f).hasError(e);
  }

  getFieldForm(f: string) {
    return this.form.get(f);
  }
}

export class RegistrationForm {
  public form: FormGroup;
  public errors: string = null;
  public submitted = false;

  constructor(data) {
    // Initialize the form builder
    this.form = new FormBuilder().group({
      email: new FormControl(null, [Validators.required, Validators.email]),
      first_name: new FormControl(null, [Validators.required]),
      last_name: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required, Validators.minLength(5)])
    });
  }

  // check form if all field is valid
  get isValid() {
    return this.form.valid;
  }

  // check if form field is valid
  valid(f: any) {
    return !(!this.form.get(f).valid && this.form.get(f).touched);
  }

  // check if the form field has an error
  hasError(f: any, e: any) {
    return this.form.get(f).hasError(e);
  }

  getFieldForm(f: string) {
    return this.form.get(f);
  }
}
