import { StateService } from '@uirouter/angular';
import { BroadcastService } from './../../../../commons/services/broadcast.service';
import { Component, OnInit } from '@angular/core';
import { GetPhoneParams, PhoneNumbers } from 'src/app/commons/models/broadcast.models';
import { CreditService } from 'src/app/commons/services/credit.service';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {
  active = 1;

  public areaCode: GetPhoneParams = new GetPhoneParams();
  public region: GetPhoneParams = new GetPhoneParams();

  public selectedNumbers: PhoneNumbers[] = Array<PhoneNumbers>();

  constructor(
    public broadcastService: BroadcastService,
    private creditService: CreditService,
    private stateService: StateService
  ) { }

  get numbers() {
    return this.selectedNumbers.map(item => item.phone_number);
  }

  ngOnInit() {
    this.fetchTollFree();
  }

  searchByAreacode() {
    this.search(this.areaCode);
  }

  searchByCity() {
    this.search(this.region);
  }

  fetchTollFree() {
    const params = new GetPhoneParams();
    this.broadcastService.fetchTollFreeNumber(params);
  }

  addNumber(instance: PhoneNumbers) {
    if (!this.selectedNumbers.includes(instance)) {
      this.selectedNumbers.push(instance);
    }
  }

  search(data) {
    this.broadcastService.fetchLocalNumber(data);
  }

  saveNumber() {
    this.creditService.setLocalData(this.numbers, 'phone_numbers');
  }

  saveItem() {
    const item = [{
      item_type: 'phone_number',
      name: `${this.numbers.length} phone numbers`,
      quantity: this.numbers.length
    }];
    this.creditService.setLocalData(item, 'list_items');
  }

  purchaseNumber() {
    this.saveNumber();
    this.saveItem();

    this.stateService.go('credit-confirm', {returnPage: 'phone-select'});
  }

}
