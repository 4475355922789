export class Property  {
  name: string = null;
  description: string = null;
  property_type: string = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class Properties {
  results: Property[] = Array<Property>();

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class PropertyRoom  {
  rent_price: string = null;
  deposit_price: string = null;
  is_negotiable: boolean = null;
  bedroom_count: number = null;
  bathroom_count: number = null;
  year_built: number = null;
  sq_foot: string = null;
  date_availability: string = null;
  is_age_restricted: boolean = null;
  is_pet_allowed: boolean = null;
  is_smoking_allowed: boolean = null;
  property_obj: Property = new Property();

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

