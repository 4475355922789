import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/app/commons/constants/ws.contants';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { AuthService } from '../auth/auth.service';

export const WS_ENDPOINT = environment.wsEndpoint;
export const VOICE_WS_ENDPOINT = environment.voiceEndpoint;

// export const WS_ENV_ENDPOINT
import { WP_ENV } from '../../constants/ws.contants';

@Injectable({
  providedIn: 'root'
})
export class WebhookService {

  private connection$: WebSocketSubject<any>;
  private connectToVoiceBroadcast$: WebSocketSubject<any>;
  constructor(
    private auth: AuthService,
    private http: HttpClient
  ) { }

  /**
   * connect to sms
   */
  async connect() {

    if (!this.connection$ || this.connection$.closed) {
      this.connection$ = await this.getNewWebSocket();
      return this.connection$;
    }
  }

  private getNewWebSocket() {
    const id = _.get(this.auth.getToken(), ['user_id'], null);
    return webSocket(WP_ENV(id).wsEndpoint);
  }


  receiveMessage() {
    this.connection$.subscribe( (msg: any) => {
      return msg;
    });
  }


  /**
   * connect to voice broadcast
   */
  async connectToVoiceBroadcast() {
    if (!this.connectToVoiceBroadcast$ || this.connectToVoiceBroadcast$.closed) {
      this.connectToVoiceBroadcast$ = await this.getVoiceBroadcastSocket();
      return this.connectToVoiceBroadcast$;
    }
  }

  private getVoiceBroadcastSocket() {
    const id = _.get(this.auth.getToken(), ['user_id'], null);
    return webSocket(WP_ENV(id).voiceEndpoint);
  }
}
