import { CreditService } from 'src/app/commons/services/credit.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  @Output() addCard: EventEmitter<boolean> = new EventEmitter();
  public activeCard = null;
  constructor(
    private creditService: CreditService
  ) { }

  get cards() {
    return this.creditService.creditCards;
  }

  get isFetching() {
    return this.creditService.isProcessing;
  }

  ngOnInit() {

  }

  addCardModal() {
    this.addCard.emit(true);
  }

  close() {
    this.closeModal.emit(true);
  }

  selectCard(cardId, index) {
    this.activeCard = index;
    this.creditService.getCard(cardId);
  }
}
