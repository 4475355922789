import { CreditService } from 'src/app/commons/services/credit.service';
import { Component, OnInit } from '@angular/core';
import { Payment } from 'src/app/commons/models/credit.models';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {
  public payment: Payment = new Payment();

  constructor(
    private creditService: CreditService
  ) { }

  ngOnInit() {
    this.loadPayment();
  }

  stringToDatetime(sDate) {
    const date = new Date(sDate);
    return date;
  }

  loadPayment() {
    this.creditService.payment.subscribe((resp) => {
      this.payment = resp;
    });
  }
}
