import { PropertyRoom } from './listings.models';

// tslint:disable: variable-name
export class Prospect  {
  isSelected = false;
  isTextSelected = false;
  email_id: string = null;
  type: string = null;
  name: string = null;
  email: string = null;
  contact_number: string = null;
  contact: ContactDetail = new ContactDetail();
  property_room: PropertyRoom = new PropertyRoom();
  email_inbox: number = null;
  text_inbox: number = null;


  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class ProspectList {
  count: number = null;
  next: string = null;
  previous: string = null;
  results: Prospect[] = Array<Prospect>();

  constructor(data = {}) {
    Object.assign(this, data);
  }

}

export class ProspectFilter {
  prospect_name: string = null;
  voucher: string = null;
  city: string = null;
  miles: string = null;
  bedrooms: number = null;
  rent_min: number = null;
  rent_max: number = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }

}

export class ContactDetail {
  id: string = null;
  prospect: string = null;
  contact_number: string = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}


export class Contacts {
  count: number = null;
  next: string = null;
  previous: string = null;
  results: ContactDetail[] = Array<ContactDetail>();

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
