export const TIME_ZONES: object[] = [
    { timezone: 'Africa/Abidjan' },
    { timezone: 'Africa/Algiers' },
    { timezone: 'Africa/Bissau' },
    { timezone: 'Africa/Cairo' },
    { timezone: 'Africa/Casablanca' },
    { timezone: 'Africa/Ceuta' },
    { timezone: 'Africa/El_Aaiun' },
    { timezone: 'Africa/Johannesburg' },
    { timezone: 'Africa/Juba' },
    { timezone: 'Africa/Khartoum' },
    { timezone: 'Africa/Lagos' },
    { timezone: 'Africa/Maputo' },
    { timezone: 'Africa/Monrovia' },
    { timezone: 'Africa/Nairobi' },
    { timezone: 'Africa/Ndjamena' },
    { timezone: 'Africa/Sao_Tome' },
    { timezone: 'Africa/Tripoli' },
    { timezone: 'Africa/Tunis' },
    { timezone: 'Africa/Windhoek' },
    { timezone: 'America/Adak' },
    { timezone: 'America/Anchorage' },
    { timezone: 'America/Araguaina' },
    { timezone: 'America/Argentina/Buenos_Aires' },
    { timezone: 'America/Argentina/Catamarca' },
    { timezone: 'America/Argentina/Cordoba' },
    { timezone: 'America/Argentina/Jujuy' },
    { timezone: 'America/Argentina/La_Rioja' },
    { timezone: 'America/Argentina/Mendoza' },
    { timezone: 'America/Argentina/Rio_Gallegos' },
    { timezone: 'America/Argentina/Salta' },
    { timezone: 'America/Argentina/San_Juan' },
    { timezone: 'America/Argentina/San_Luis' },
    { timezone: 'America/Argentina/Tucuman' },
    { timezone: 'America/Argentina/Ushuaia' },
    { timezone: 'America/Asuncion' },
    { timezone: 'America/Atikokan' },
    { timezone: 'America/Bahia' },
    { timezone: 'America/Bahia_Banderas' },
    { timezone: 'America/Barbados' },
    { timezone: 'America/Belem' },
    { timezone: 'America/Belize' },
    { timezone: 'America/Blanc-Sablon' },
    { timezone: 'America/Boa_Vista' },
    { timezone: 'America/Bogota' },
    { timezone: 'America/Boise' },
    { timezone: 'America/Cambridge_Bay' },
    { timezone: 'America/Campo_Grande' },
    { timezone: 'America/Cancun' },
    { timezone: 'America/Caracas' },
    { timezone: 'America/Cayenne' },
    { timezone: 'America/Chicago' },
    { timezone: 'America/Chihuahua' },
    { timezone: 'America/Costa_Rica' },
    { timezone: 'America/Creston' },
    { timezone: 'America/Cuiaba' },
    { timezone: 'America/Curacao' },
    { timezone: 'America/Danmarkshavn' },
    { timezone: 'America/Dawson' },
    { timezone: 'America/Dawson_Creek' },
    { timezone: 'America/Denver' },
    { timezone: 'America/Detroit' },
    { timezone: 'America/Edmonton' },
    { timezone: 'America/Eirunepe' },
    { timezone: 'America/El_Salvador' },
    { timezone: 'America/Fort_Nelson' },
    { timezone: 'America/Fortaleza' },
    { timezone: 'America/Glace_Bay' },
    { timezone: 'America/Goose_Bay' },
    { timezone: 'America/Grand_Turk' },
    { timezone: 'America/Guatemala' },
    { timezone: 'America/Guayaquil' },
    { timezone: 'America/Guyana' },
    { timezone: 'America/Halifax' },
    { timezone: 'America/Havana' },
    { timezone: 'America/Hermosillo' },
    { timezone: 'America/Indiana/Indianapolis' },
    { timezone: 'America/Indiana/Knox' },
    { timezone: 'America/Indiana/Marengo' },
    { timezone: 'America/Indiana/Petersburg' },
    { timezone: 'America/Indiana/Tell_City' },
    { timezone: 'America/Indiana/Vevay' },
    { timezone: 'America/Indiana/Vincennes' },
    { timezone: 'America/Indiana/Winamac' },
    { timezone: 'America/Inuvik' },
    { timezone: 'America/Iqaluit' },
    { timezone: 'America/Jamaica' },
    { timezone: 'America/Juneau' },
    { timezone: 'America/Kentucky/Louisville' },
    { timezone: 'America/Kentucky/Monticello' },
    { timezone: 'America/La_Paz' },
    { timezone: 'America/Lima' },
    { timezone: 'America/Los_Angeles' },
    { timezone: 'America/Maceio' },
    { timezone: 'America/Managua' },
    { timezone: 'America/Manaus' },
    { timezone: 'America/Martinique' },
    { timezone: 'America/Matamoros' },
    { timezone: 'America/Mazatlan' },
    { timezone: 'America/Menominee' },
    { timezone: 'America/Merida' },
    { timezone: 'America/Metlakatla' },
    { timezone: 'America/Mexico_City' },
    { timezone: 'America/Miquelon' },
    { timezone: 'America/Moncton' },
    { timezone: 'America/Monterrey' },
    { timezone: 'America/Montevideo' },
    { timezone: 'America/Nassau' },
    { timezone: 'America/New_York' },
    { timezone: 'America/Nipigon' },
    { timezone: 'America/Nome' },
    { timezone: 'America/Noronha' },
    { timezone: 'America/North_Dakota/Beulah' },
    { timezone: 'America/North_Dakota/Center' },
    { timezone: 'America/North_Dakota/New_Salem' },
    { timezone: 'America/Nuuk' },
    { timezone: 'America/Ojinaga' },
    { timezone: 'America/Panama' },
    { timezone: 'America/Pangnirtung' },
    { timezone: 'America/Paramaribo' },
    { timezone: 'America/Phoenix' },
    { timezone: 'America/Port-au-Prince' },
    { timezone: 'America/Port_of_Spain' },
    { timezone: 'America/Porto_Velho' },
    { timezone: 'America/Puerto_Rico' },
    { timezone: 'America/Punta_Arenas' },
    { timezone: 'America/Rainy_River' },
    { timezone: 'America/Rankin_Inlet' },
    { timezone: 'America/Recife' },
    { timezone: 'America/Regina' },
    { timezone: 'America/Resolute' },
    { timezone: 'America/Rio_Branco' },
    { timezone: 'America/Santarem' },
    { timezone: 'America/Santiago' },
    { timezone: 'America/Santo_Domingo' },
    { timezone: 'America/Sao_Paulo' },
    { timezone: 'America/Scoresbysund' },
    { timezone: 'America/Sitka' },
    { timezone: 'America/St_Johns' },
    { timezone: 'America/Swift_Current' },
    { timezone: 'America/Tegucigalpa' },
    { timezone: 'America/Thule' },
    { timezone: 'America/Thunder_Bay' },
    { timezone: 'America/Tijuana' },
    { timezone: 'America/Toronto' },
    { timezone: 'America/Vancouver' },
    { timezone: 'America/Whitehorse' },
    { timezone: 'America/Winnipeg' },
    { timezone: 'America/Yakutat' },
    { timezone: 'America/Yellowknife' },
    { timezone: 'Antarctica/Casey' },
    { timezone: 'Antarctica/Davis' },
    { timezone: 'Antarctica/DumontDUrville' },
    { timezone: 'Antarctica/Macquarie' },
    { timezone: 'Antarctica/Mawson' },
    { timezone: 'Antarctica/Palmer' },
    { timezone: 'Antarctica/Rothera' },
    { timezone: 'Antarctica/Syowa' },
    { timezone: 'Antarctica/Troll' },
    { timezone: 'Antarctica/Vostok' },
    { timezone: 'Asia/Almaty' },
    { timezone: 'Asia/Amman' },
    { timezone: 'Asia/Anadyr' },
    { timezone: 'Asia/Aqtau' },
    { timezone: 'Asia/Aqtobe' },
    { timezone: 'Asia/Ashgabat' },
    { timezone: 'Asia/Atyrau' },
    { timezone: 'Asia/Baghdad' },
    { timezone: 'Asia/Baku' },
    { timezone: 'Asia/Bangkok' },
    { timezone: 'Asia/Barnaul' },
    { timezone: 'Asia/Beirut' },
    { timezone: 'Asia/Bishkek' },
    { timezone: 'Asia/Brunei' },
    { timezone: 'Asia/Chita' },
    { timezone: 'Asia/Choibalsan' },
    { timezone: 'Asia/Colombo' },
    { timezone: 'Asia/Damascus' },
    { timezone: 'Asia/Dhaka' },
    { timezone: 'Asia/Dili' },
    { timezone: 'Asia/Dubai' },
    { timezone: 'Asia/Dushanbe' },
    { timezone: 'Asia/Famagusta' },
    { timezone: 'Asia/Gaza' },
    { timezone: 'Asia/Hebron' },
    { timezone: 'Asia/Ho_Chi_Minh' },
    { timezone: 'Asia/Hong_Kong' },
    { timezone: 'Asia/Hovd' },
    { timezone: 'Asia/Irkutsk' },
    { timezone: 'Asia/Jakarta' },
    { timezone: 'Asia/Jayapura' },
    { timezone: 'Asia/Jerusalem' },
    { timezone: 'Asia/Kabul' },
    { timezone: 'Asia/Kamchatka' },
    { timezone: 'Asia/Karachi' },
    { timezone: 'Asia/Kathmandu' },
    { timezone: 'Asia/Khandyga' },
    { timezone: 'Asia/Kolkata' },
    { timezone: 'Asia/Krasnoyarsk' },
    { timezone: 'Asia/Kuala_Lumpur' },
    { timezone: 'Asia/Kuching' },
    { timezone: 'Asia/Macau' },
    { timezone: 'Asia/Magadan' },
    { timezone: 'Asia/Makassar' },
    { timezone: 'Asia/Manila' },
    { timezone: 'Asia/Nicosia' },
    { timezone: 'Asia/Novokuznetsk' },
    { timezone: 'Asia/Novosibirsk' },
    { timezone: 'Asia/Omsk' },
    { timezone: 'Asia/Oral' },
    { timezone: 'Asia/Pontianak' },
    { timezone: 'Asia/Pyongyang' },
    { timezone: 'Asia/Qatar' },
    { timezone: 'Asia/Qostanay' },
    { timezone: 'Asia/Qyzylorda' },
    { timezone: 'Asia/Riyadh' },
    { timezone: 'Asia/Sakhalin' },
    { timezone: 'Asia/Samarkand' },
    { timezone: 'Asia/Seoul' },
    { timezone: 'Asia/Shanghai' },
    { timezone: 'Asia/Singapore' },
    { timezone: 'Asia/Srednekolymsk' },
    { timezone: 'Asia/Taipei' },
    { timezone: 'Asia/Tashkent' },
    { timezone: 'Asia/Tbilisi' },
    { timezone: 'Asia/Tehran' },
    { timezone: 'Asia/Thimphu' },
    { timezone: 'Asia/Tokyo' },
    { timezone: 'Asia/Tomsk' },
    { timezone: 'Asia/Ulaanbaatar' },
    { timezone: 'Asia/Urumqi' },
    { timezone: 'Asia/Ust-Nera' },
    { timezone: 'Asia/Vladivostok' },
    { timezone: 'Asia/Yakutsk' },
    { timezone: 'Asia/Yangon' },
    { timezone: 'Asia/Yekaterinburg' },
    { timezone: 'Asia/Yerevan' },
    { timezone: 'Atlantic/Azores' },
    { timezone: 'Atlantic/Bermuda' },
    { timezone: 'Atlantic/Canary' },
    { timezone: 'Atlantic/Cape_Verde' },
    { timezone: 'Atlantic/Faroe' },
    { timezone: 'Atlantic/Madeira' },
    { timezone: 'Atlantic/Reykjavik' },
    { timezone: 'Atlantic/South_Georgia' },
    { timezone: 'Atlantic/Stanley' },
    { timezone: 'Australia/Adelaide' },
    { timezone: 'Australia/Brisbane' },
    { timezone: 'Australia/Broken_Hill' },
    { timezone: 'Australia/Currie' },
    { timezone: 'Australia/Darwin' },
    { timezone: 'Australia/Eucla' },
    { timezone: 'Australia/Hobart' },
    { timezone: 'Australia/Lindeman' },
    { timezone: 'Australia/Lord_Howe' },
    { timezone: 'Australia/Melbourne' },
    { timezone: 'Australia/Perth' },
    { timezone: 'Australia/Sydney' },
    { timezone: 'CET' },
    { timezone: 'CST6CDT' },
    { timezone: 'EET' },
    { timezone: 'EST' },
    { timezone: 'EST5EDT' },
    { timezone: 'Etc/GMT' },
    { timezone: 'Etc/GMT+1' },
    { timezone: 'Etc/GMT+10' },
    { timezone: 'Etc/GMT+11' },
    { timezone: 'Etc/GMT+12' },
    { timezone: 'Etc/GMT+2' },
    { timezone: 'Etc/GMT+3' },
    { timezone: 'Etc/GMT+4' },
    { timezone: 'Etc/GMT+5' },
    { timezone: 'Etc/GMT+6' },
    { timezone: 'Etc/GMT+7' },
    { timezone: 'Etc/GMT+8' },
    { timezone: 'Etc/GMT+9' },
    { timezone: 'Etc/GMT-1' },
    { timezone: 'Etc/GMT-10' },
    { timezone: 'Etc/GMT-11' },
    { timezone: 'Etc/GMT-12' },
    { timezone: 'Etc/GMT-13' },
    { timezone: 'Etc/GMT-14' },
    { timezone: 'Etc/GMT-2' },
    { timezone: 'Etc/GMT-3' },
    { timezone: 'Etc/GMT-4' },
    { timezone: 'Etc/GMT-5' },
    { timezone: 'Etc/GMT-6' },
    { timezone: 'Etc/GMT-7' },
    { timezone: 'Etc/GMT-8' },
    { timezone: 'Etc/GMT-9' },
    { timezone: 'Etc/UTC' },
    { timezone: 'Europe/Amsterdam' },
    { timezone: 'Europe/Andorra' },
    { timezone: 'Europe/Astrakhan' },
    { timezone: 'Europe/Athens' },
    { timezone: 'Europe/Belgrade' },
    { timezone: 'Europe/Berlin' },
    { timezone: 'Europe/Brussels' },
    { timezone: 'Europe/Bucharest' },
    { timezone: 'Europe/Budapest' },
    { timezone: 'Europe/Chisinau' },
    { timezone: 'Europe/Copenhagen' },
    { timezone: 'Europe/Dublin' },
    { timezone: 'Europe/Gibraltar' },
    { timezone: 'Europe/Helsinki' },
    { timezone: 'Europe/Istanbul' },
    { timezone: 'Europe/Kaliningrad' },
    { timezone: 'Europe/Kiev' },
    { timezone: 'Europe/Kirov' },
    { timezone: 'Europe/Lisbon' },
    { timezone: 'Europe/London' },
    { timezone: 'Europe/Luxembourg' },
    { timezone: 'Europe/Madrid' },
    { timezone: 'Europe/Malta' },
    { timezone: 'Europe/Minsk' },
    { timezone: 'Europe/Monaco' },
    { timezone: 'Europe/Moscow' },
    { timezone: 'Europe/Oslo' },
    { timezone: 'Europe/Paris' },
    { timezone: 'Europe/Prague' },
    { timezone: 'Europe/Riga' },
    { timezone: 'Europe/Rome' },
    { timezone: 'Europe/Samara' },
    { timezone: 'Europe/Saratov' },
    { timezone: 'Europe/Simferopol' },
    { timezone: 'Europe/Sofia' },
    { timezone: 'Europe/Stockholm' },
    { timezone: 'Europe/Tallinn' },
    { timezone: 'Europe/Tirane' },
    { timezone: 'Europe/Ulyanovsk' },
    { timezone: 'Europe/Uzhgorod' },
    { timezone: 'Europe/Vienna' },
    { timezone: 'Europe/Vilnius' },
    { timezone: 'Europe/Volgograd' },
    { timezone: 'Europe/Warsaw' },
    { timezone: 'Europe/Zaporozhye' },
    { timezone: 'Europe/Zurich' },
    { timezone: 'HST' },
    { timezone: 'Indian/Chagos' },
    { timezone: 'Indian/Christmas' },
    { timezone: 'Indian/Cocos' },
    { timezone: 'Indian/Kerguelen' },
    { timezone: 'Indian/Mahe' },
    { timezone: 'Indian/Maldives' },
    { timezone: 'Indian/Mauritius' },
    { timezone: 'Indian/Reunion' },
    { timezone: 'MET' },
    { timezone: 'MST' },
    { timezone: 'MST7MDT' },
    { timezone: 'PST8PDT' },
    { timezone: 'Pacific/Apia' },
    { timezone: 'Pacific/Auckland' },
    { timezone: 'Pacific/Bougainville' },
    { timezone: 'Pacific/Chatham' },
    { timezone: 'Pacific/Chuuk' },
    { timezone: 'Pacific/Easter' },
    { timezone: 'Pacific/Efate' },
    { timezone: 'Pacific/Enderbury' },
    { timezone: 'Pacific/Fakaofo' },
    { timezone: 'Pacific/Fiji' },
    { timezone: 'Pacific/Funafuti' },
    { timezone: 'Pacific/Galapagos' },
    { timezone: 'Pacific/Gambier' },
    { timezone: 'Pacific/Guadalcanal' },
    { timezone: 'Pacific/Guam' },
    { timezone: 'Pacific/Honolulu' },
    { timezone: 'Pacific/Kiritimati' },
    { timezone: 'Pacific/Kosrae' },
    { timezone: 'Pacific/Kwajalein' },
    { timezone: 'Pacific/Majuro' },
    { timezone: 'Pacific/Marquesas' },
    { timezone: 'Pacific/Nauru' },
    { timezone: 'Pacific/Niue' },
    { timezone: 'Pacific/Norfolk' },
    { timezone: 'Pacific/Noumea' },
    { timezone: 'Pacific/Pago_Pago' },
    { timezone: 'Pacific/Palau' },
    { timezone: 'Pacific/Pitcairn' },
    { timezone: 'Pacific/Pohnpei' },
    { timezone: 'Pacific/Port_Moresby' },
    { timezone: 'Pacific/Rarotonga' },
    { timezone: 'Pacific/Tahiti' },
    { timezone: 'Pacific/Tarawa' },
    { timezone: 'Pacific/Tongatapu' },
    { timezone: 'Pacific/Wake' },
    { timezone: 'Pacific/Wallis' },
    { timezone: 'WET' },
]