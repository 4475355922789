import { Directive, ElementRef, HostListener, Output, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[appScrollToTop]'
})
export class ScrollToTopDirective {
  @Output() topReached: EventEmitter<boolean> = new EventEmitter();
  @Output() heightChanged: EventEmitter<boolean> = new EventEmitter();


  constructor(private el: ElementRef) {}


  @HostListener('scroll', ['$event'])
  onElementScroll() {
    const scrollPosition = this.el.nativeElement.scrollTop;
    if (scrollPosition === 0) {
      this.topReached.emit(true);
    };

  }
}
