import * as _ from 'lodash';
import { ProspectList } from './../../../../../commons/models/prospects.models';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { CreateComponent } from '../create.component';
import { ComposeEmail, ComposeText, ContactList } from 'src/app/commons/models/inbox.models';
import { CustomError } from 'src/app/commons/models/exception.model';

import phone from 'phone';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends CreateComponent implements OnInit {
  private modalRef: NgbModalRef;
  private $prospects: ProspectList = new ProspectList();
  private interval: any; // will remove when send message is ready
  private $textMessage: ComposeText = new ComposeText();
  private $threadMessage: any;
  private contactList: any = [];

  public progressPercent = 0;
  public creditError: CustomError = new CustomError();
  public sendError: CustomError = new CustomError();
  public conflictCount = 0;
  public invalidNumber = 0;
  public isSending = false;
  public isSavingList = false;
  public isFromProspect = false;
  public contactType = 'text';
  public prev = 'inbox-compose';
  public newContactList: ContactList = new ContactList();
  public broadcastID = null;


  @ViewChild('copyModal', {static: true}) copyModal: ElementRef;
  @ViewChild('prospectLists', {static: true}) prospectLists: ElementRef;
  @ViewChild('validateModal', {static: true}) validateModal: ElementRef;
  @ViewChild('successModal', {static: true}) successModal: ElementRef;


  selectedContacts = [];
  selectedContactNumber = [];
  validatedContacts = [];


  async ngOnInit() {
    super.ngOnInit();
    this.prev = (this.state.params && this.state.params.prev) ? this.state.params.prev : this.prev;
    this.contactType = (this.state.params && this.state.params.module === 'voice') ? 'voice' : 'text';
    await this.prospectService.getProspects();
    this.selectedContacts = this.state.params.contacts;

    // initialize prospects
    this.prospectService.prospects.subscribe(
      resp => {
        this.$prospects = new ProspectList(resp);
      }
    );

    // initialize text message to send
    this.inboxService.textMessage.subscribe(
      resp => {
        this.$textMessage = new ComposeText(resp);
      }
    );
    if (this.state.params.autoOpen) { this.open(this.prospectLists); }
  }

  get isProgressComplete() {
    return this.progressPercent === 100 ? true : false;
  }

  get prospects() {
    return this.$prospects;
  }

  get textMessage() {
    return this.$textMessage;
  }

  get threadMessage() {
    return this.$threadMessage;
  }

  redirectToBroadCast() {
    const broadcastId = this.contactType === 'voice' ? this.broadcastID : this.$threadMessage.broadcast;

    this.modalService.dismissAll();
    this.state.go('broadcast-detail', {id: broadcastId});
  }

  checkDuplicates(isProspect) {
    this.progressPercent = 0;
    this.invalidNumber = 0;
    this.conflictCount = 0;
    this.validatedContacts = [];
    this.selectedContactNumber = [];
    const verifiedNumbers = [];
    const contactCount = this.newContactList.contacts.length;
    if (!isProspect) {
      this.newContactList.contacts.forEach((phoneNumber, index) => {
        const checkedNumber = phone(phoneNumber);

        if (!!checkedNumber.length) {
          if (verifiedNumbers.includes(checkedNumber[0])) {
            this.conflictCount++;
          } else {
            verifiedNumbers.push(checkedNumber[0]);
          }
        } else {
          this.invalidNumber++;
        }

        setTimeout(() => {
          this.progressPercent = (index + 1) * 100 / contactCount;
        }, 1000);
      });

      // update contact list with the validated one
    } else {
      this.isFromProspect = true;
      this.selectedContacts.forEach((item, index) => {
        const prospect = this.getProspect(item);
        if (prospect) {
          const contactNumber = prospect.contact_number;
          const checkedNumber = phone(contactNumber);
          if (!!checkedNumber.length) {
            if (verifiedNumbers.includes(checkedNumber[0])) {
              this.conflictCount++;
            } else {
              // this.validatedContacts.push(prospect.email_id);
              this.validatedContacts.push({email_id: prospect.email_id, formatted_num: checkedNumber[0]});
              verifiedNumbers.push(checkedNumber[0]);
            }
          } else {
            this.invalidNumber++;
          }
        }
        setTimeout(() => {
          this.progressPercent = (index + 1) * 100 / this.selectedContacts.length;
        }, 1000);
      });
    }

    this.newContactList.contacts = verifiedNumbers;
  }

  isSelected(id) {
    return this.selectedContacts.includes(id);
  }

  getProspect(emailId) {
    return this.prospects.results.find(item => item.email_id === emailId);
  }

  selectedChanged(e) {
    const isChecked = e.target.checked;
    const id = e.target.id;

    if (isChecked && !this.isSelected(id)) {
      this.selectedContacts.push(id);
    } else {
      this.selectedContacts = this.selectedContacts.filter(item => item !== id);
    }
  }

  getEmail(id) {
    let email = '';
    this.prospects.results.forEach(item => {
      if (item.email_id === id) {
        email = item.email;
      }
    });

    return email;
  }

  open(modal, modalName?, isProspect?) {
    const isValidate = (modalName === 'validate' || modalName === 'select');
    const size = isValidate ? 'xl' : 'lg';
    const centered = true;

    if (!!this.modalRef) {this.modalRef.dismiss(); }
    this.modalRef = this.modalService.open(modal, {size, centered});

    // temporary animation for progress bar
    if (isValidate) {
      this.checkDuplicates(isProspect);
    }

  }

  progressBar() {
    if (this.progressPercent >= 100) {
      clearInterval(this.interval);
    } else {
      this.progressPercent += 5;
    }
  }

  sendText() {
    const prospect = {contact_list: this.contactList, prospect: this.validatedContacts, contact: []};
    const data = Object.assign(this.textMessage, prospect);

    this.isSending = true;
    this.inboxService.sendText(data)
    .then(
      (resp: any) => {
        this.$threadMessage = resp;
        this.isSending = false;
        this.isSavingList = false;
        this.open(this.successModal);
      }
    )
    .catch(
      err => {
        this.isSending = false;
        this.isSavingList = false;
        const code = err.error.code;
        if (code === 'credits') {
          this.creditError = new CustomError(err.error);
        } else {
          this.sendError = new CustomError(err.error);
        }
      }
    );
  }

  outBoundCall() {
    this.voiceService.getoutBoundCallPayload.subscribe( resp => {
      const prospect = {contact_list: this.contactList, prospect: this.validatedContacts, contact: []};
      const data = Object.assign(resp, prospect);
      this.voiceService.initiateOutBoundCall(data)
      .then( (response: any) => {
        this.broadcastID = response.broadcast;
        this.isSending = false;
        this.isSavingList = false;
        this.open(this.successModal);
      })
      .catch( err => {
        this.isSending = false;
        this.isSavingList = false;
        const code = err.error.code;
        if (code === 'credits') {
          this.creditError = new CustomError(err.error);
        } else {
          this.sendError = new CustomError(err.error);
        }
      });
    }).unsubscribe();
  }

  onSend() {
    if (this.state.params && this.state.params.module === 'voice') {
      this.outBoundCall();
    } else {
      this.sendText();
    }

  }

  saveList() {
    this.isSavingList = true;
    // +639239128347, 09283736253, +639183726352

    this.prospectService.createContactList(this.newContactList)
    .then((resp: any) => {
      setTimeout(() => {
        // this.open(this.successModal);
        // this.isSavingList = false;
        this.contactList = resp.id;
        this.onSend();
      }, 1000);
    })
    .catch(err => {
      this.isSavingList = false;
    });
  }

  purchaseCredit(quantity) {
    this.creditService.setLocalData(quantity, 'credit_quantity');
    this.modalRef.dismiss();
    this.state.go('credit-confirm');
  }

  validateList(event: ContactList, validatemodal) {
    this.newContactList = event;

    // open validation modal
    this.open(validatemodal, 'validate');
  }

}

