import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { PhoneNumbers, BroadcastList, Broadcast, BroadcastMessageList, BroadcastMessage } from '../models/broadcast.models';
import {
  API_AVAILABLE_PHONE,
  USER_PHONE_NUMBER,
  API_BROADCAST_LIST,
  API_BROADCAST_DETAIL,
  API_BROADCAST_MESSAGE
} from '../constants/api.constants';
import { urlsafe, queryparams } from '../utils/http.utils';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  private phoneNumbers$: BehaviorSubject<PhoneNumbers[]>  = new BehaviorSubject(Array<PhoneNumbers>());
  private broadcastDetail$: BehaviorSubject<Broadcast> = new BehaviorSubject(new Broadcast());
  private broadcastDetailOverview$: BehaviorSubject<BroadcastMessage> = new BehaviorSubject(new BroadcastMessage());
  private broadcastMessages$: BehaviorSubject<BroadcastMessageList> = new BehaviorSubject(new BroadcastMessageList());
  private broadcastMessageDetails$: BehaviorSubject<BroadcastMessage> = new BehaviorSubject(new BroadcastMessage());


  public broadcasts: BroadcastList = new BroadcastList();
  public AvailableLocalNumber: PhoneNumbers[] = Array<PhoneNumbers>();
  public AvailableTollFreeNumber: PhoneNumbers[] = Array<PhoneNumbers>();
  public isFetching = false;
  public isFetchingToll = false;


  constructor(
    private http: HttpClient
  ) { }

  get phoneNumbers() {
    return this.phoneNumbers$.asObservable();
  }

  get broadcastMessages() {
    return this.broadcastMessages$.asObservable();
  }

  get broadcastDetail() {
    return this.broadcastDetail$.asObservable();
  }

  get broadcastMessageDetails() {
    return this.broadcastMessageDetails$.asObservable();
  }

  get broadcastDetailOverview() {
    return this.broadcastDetailOverview$.asObservable();
  }

  fetchLocalNumber(data) {
    data = {...data.cleanedData()};
    this.isFetching = true;
    return this.http.get(API_AVAILABLE_PHONE + queryparams(data))
      .toPromise()
      .then((resp: PhoneNumbers[]) => {
        this.isFetching = false;
        this.AvailableLocalNumber = resp;
      });
  }

  fetchTollFreeNumber(data) {
    data = {...data.cleanedData()};
    this.isFetchingToll = true;

    return this.http.get(API_AVAILABLE_PHONE + queryparams(data))
      .toPromise()
      .then((resp: PhoneNumbers[]) => {
        this.isFetchingToll = false;
        this.AvailableTollFreeNumber = resp;
      });
  }

  fetchPhoneNumber() {
    return this.http.get(USER_PHONE_NUMBER)
      .toPromise()
      .then((resp: PhoneNumbers[]) => {
        this.phoneNumbers$.next(resp);
      });
  }

  fetchBroadcastList(data) {
    return this.http.get(API_BROADCAST_LIST + queryparams(data))
    .toPromise()
    .then((resp: BroadcastList) => {
      this.broadcasts = resp;
    });
  }

  fetchBroadcast(id) {
    return this.http.get(urlsafe(API_BROADCAST_DETAIL, id))
    .toPromise()
    .then((resp: Broadcast) => {
      this.broadcastDetail$.next(resp);
    });
  }

  fetchBroadcastMessageList(id, params?) {
    const data = params ? queryparams(params) : '';
    return this.http.get(urlsafe(API_BROADCAST_DETAIL, id, 'messages') + data)
    .toPromise()
    .then((resp: BroadcastMessageList) => {
      this.broadcastMessages$.next(resp);
    });
  }

  fetchBroadcastMessageDetails(id) {
    return this.http.get(urlsafe(API_BROADCAST_MESSAGE, id))
    .toPromise()
    .then((resp: BroadcastMessage) => {
      this.broadcastMessageDetails$.next(resp);
    });
  }

  fetchBroadcastRecentActivity(id: any) {
    return this.http.get(urlsafe(API_BROADCAST_DETAIL, id, 'overviews'))
    .toPromise()
    .then((resp: BroadcastMessage) => {
      this.broadcastDetailOverview$.next(resp);
    });

  }
}
