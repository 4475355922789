
import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';
import { ProspectService } from 'src/app/commons/services/prospect.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComposeTextForm } from 'src/app/commons/forms/inbox.forms';
import { ComposeText } from 'src/app/commons/models/inbox.models';

import { CreditService } from 'src/app/commons/services/credit.service';
import { InboxService } from 'src/app/commons/services/inbox.service';
import { BroadcastService } from 'src/app/commons/services/broadcast.service';
import { VoiceService } from 'src/app/commons/services/voice.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  public form: ComposeTextForm;

  constructor(
    public state: StateService,
    public modalService: NgbModal,
    public prospectService: ProspectService,
    public inboxService: InboxService,
    public creditService: CreditService,
    public broadcastService: BroadcastService,
    public voiceService: VoiceService,

  ) { }

  ngOnInit() {
    this.form = new ComposeTextForm(new ComposeText());
  }

}
