import { StateService } from '@uirouter/angular';
import { BroadcastService } from './../../../commons/services/broadcast.service';
import { ListingsService } from './../../../commons/services/listings.service';
import { Component, OnInit } from '@angular/core';

import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import { ProspectService } from 'src/app/commons/services/prospect.service';
import { ProspectList, ProspectFilter } from 'src/app/commons/models/prospects.models';
import { ProspectFilterForm } from 'src/app/commons/forms/prospects.forms';
import { Properties } from 'src/app/commons/models/listings.models';
import { CustomError } from 'src/app/commons/models/exception.model';
import { CreditService } from 'src/app/commons/services/credit.service';
import { CreditBalance } from 'src/app/commons/models/credit.models';
import { VoiceService } from 'src/app/commons/services/voice.service';



@Component({
  selector: 'app-prospects',
  templateUrl: './prospects.component.html',
  styleUrls: ['./prospects.component.scss']
})
export class ProspectsComponent implements OnInit {
  public form: ProspectFilterForm;
  public updating = false;
  public isProspect = false;
  public selectedProspects = [];
  public selectedProspectText = [];
  public creditError: CustomError;

  private emailModal: NgbModalRef;
  private $prospects: ProspectList = new ProspectList();
  private $properties: Properties = new Properties();
  private $creditBalance: CreditBalance = new CreditBalance();

  bedroomOptions = [
    {label: 'Studio', value: 'studio', checked: false},
    {label: '1', value: '1', checked: false},
    {label: '2', value: '2', checked: false},
    {label: '3', value: '3', checked: false},
    {label: '4', value: '4', checked: false},
    {label: '+5', value: '5', checked: false},
  ];
  milesOptions = [
    {name: '.1'},
    {name: '.2'},
    {name: '.5'},
    {name: '1'},
    {name: '2'},
    {name: '3'},
    {name: '5'},
  ];

  constructor(
    private prospectService: ProspectService,
    private listingService: ListingsService,
    private broadcastService: BroadcastService,
    private modalService: NgbModal,
    private creditService: CreditService,
    private state: StateService,
    public voiceService: VoiceService,
  ) { }

  ngOnInit() {
    this.form = new ProspectFilterForm(new ProspectFilter());
    this.listingService.getProperties();
    this.loadProperties();
    this.fetchProspects();
    this.fetchCreditBalance();
    this.broadcastService.fetchPhoneNumber();
    this.creditError = new CustomError();
  }

  get prospects() {
    return this.$prospects;
  }

  get properties() {
    return this.$properties;
  }

  get prospectSelectedToText() {
    return this.selectedProspectText.map(item => item.email_id);
  }

  get isSelectedAll() {
    return this.prospects.results.every(item => item.isSelected === true);
  }

  fetchCreditBalance() {
    this.creditService.creditBalance.subscribe((resp: CreditBalance) => {
      this.$creditBalance = resp;
    });
  }

  cleanObject(data: {}) {
    const clean = new ProspectFilter(data);
    Object.keys(clean).forEach(key => {
      if (!clean[key]) {
        delete clean[key];
      }
    });

    return clean;
  }

  async fetchProspects(data?) {
    await this.prospectService.getProspects(data);
    this.loadProspects();
  }

  loadProspects() {
    this.prospectService.prospects.subscribe(
      resp => {
        this.isProspect = true;
        this.$prospects = new ProspectList(resp);
      }
    );
  }

  loadProperties() {
    this.listingService.properties.subscribe(
      resp => {
        this.$properties = new Properties(resp);
      }
    );
  }

  onSubmit({value, valid}: {value: ProspectFilter, valid: boolean}) {
    this.fetchProspects(this.cleanObject(value));
  }

  prospectSelect() {
    this.filterSelectedProspects();
  }

  emailProspect(prospect, modal) {
    this.selectedProspects = [];
    this.selectedProspects.push(prospect);
    this.openEmail(modal);
    this.setProspectsIsSelect('checkbox', false);
  }

  selectAll(e) {
    const isChecked = e.target.checked;
    this.setProspectsIsSelect('checkbox', isChecked);
    this.filterSelectedProspects();
  }

  setProspectsIsSelect(type: string, value: boolean) {
    this.prospects.results.forEach(item => {
      if (type === 'checkbox') {
        item.isSelected = value;
      } else if (type === 'text') {
        item.isTextSelected = value;
      }
    });
  }

  clearFilters(form) {
    form.reset();
    this.fetchProspects();
  }

  updateProspect() {
    this.updating = true;
    this.prospectService.updateProspects()
      .then(
        resp => {
          this.updating = false;
        }
      )
      .catch(
        err => {
          this.updating = false;
        }
      );
  }

  async composeText() {
    const balance = this.$creditBalance.balance;
    if (!!balance) {
      this.state.go('inbox-create', {contactType: 'text', contacts: this.prospectSelectedToText, autoOpen: true});
    } else {
      this.creditError.code = 'credits';
      this.creditError.message = 'Insufficient credit balance.';
    }
  }

  callThisProspect(prospect) {
    this.voiceService.setCallContact(prospect);
    this.state.go('voice-create', {module: 'voice'});
  }


  filterSelectedProspects() {
    this.selectedProspects = this.prospects.results.filter(item => item.isSelected);
  }

  filterSelectedTextProspects() {
    this.selectedProspectText = this.prospects.results.filter(item => item.isTextSelected);
  }

  openEmail(content) {
    this.emailModal = this.modalService.open(content, {size: 'lg'});
  }

  closeEmail() {
    this.emailModal.close();
  }

  updateFilter(name, e) {
    let value =  e.target.value;
    if (name === 'bedrooms') {
      const selected = e.target.id;
      const isChecked = e.target.checked;

      const checkedOptions = this.bedroomOptions.reduce((acc, val) => {
        if (val.label === selected) {
          val.checked = isChecked;
        }
        if (val.checked) {
          acc.push(val.value);
        }
        return acc;
      }, []);

      value = checkedOptions.toString();
    }
    if (name === 'age_restricted' || name === 'pet_allowed' || name === 'smoking_allowed') {
      if (e.target.checked) {
        value = e.target.checked;
      } else {
        value = null;
      }
    }
    this.form.updateValue(name, value);
    this.fetchProspects(this.cleanObject(this.form.form.value));
  }
}
