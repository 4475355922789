import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { InboxService } from 'src/app/commons/services/inbox.service';
import { ProspectService } from 'src/app/commons/services/prospect.service';
import { ComposeEmailForm } from 'src/app/commons/forms/inbox.forms';
import { ComposeEmail } from 'src/app/commons/models/inbox.models';
import { CreditService } from 'src/app/commons/services/credit.service';
import { CustomError } from 'src/app/commons/models/exception.model';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  @Input() contacts = [];
  @Input() subject = '';
  @Input() inbox: number = null;
  @Input() isReply = false;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  public form: ComposeEmailForm;
  public error: CustomError = new CustomError();

  isSent = false;
  isProcessing = false;

  constructor(
    private inboxService: InboxService,
    private creditService: CreditService,
    private prospectService: ProspectService
  ) { }

  get contactNames() {
    return this.contacts.map(item => item.name);
  }

  get contactEmailsId() {
    return this.contacts.map(item => item.email_id);
  }

  ngOnInit() {
    this.form = new ComposeEmailForm(new ComposeEmail());

    if (this.isReply) {
      this.form.form.patchValue({
        subject: this.subject
      });
    }
  }

  closeSendModal() {
    this.closeModal.emit(true);
  }

  onSend({value, valid}: {value: ComposeEmail, valid: boolean}) {
    const prospect = {prospect: this.contactEmailsId};
    const data = {...value, ...prospect};
    this.isProcessing = true;
    if (this.isReply) {
      // tslint:disable-next-line: no-string-literal
      data['inbox'] = this.inbox;
    }

    if (valid) {
      this.inboxService.sendEmail(data)
      .then(
        resp => {
          this.creditService.getCreditBalance();
          this.prospectService.getProspects();
          this.inboxService.fetchThread(this.inbox);
          this.isProcessing = false;
          this.isSent = true;
          setTimeout(() => {
            this.closeSendModal();
          }, 500);
        }
      )
      .catch(
        err => {
          this.error = new CustomError(err.error);
          this.isProcessing = false;
          this.isSent = false;
        }
      );
    }
  }
}
