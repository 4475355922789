import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/commons/services/auth/auth.service';
import { User } from 'src/app/commons/models/user.models';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  public user: User = new User();

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService.setUser();
    this.authService.user.subscribe(
      resp => {
        this.user = new User(resp);
      }
    );
  }

}
