import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { InboxService } from 'src/app/commons/services/inbox.service';
import { CustomError } from 'src/app/commons/models/exception.model';


@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  @Input() details;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  @Output() inviteSent: EventEmitter<any> = new EventEmitter();

  public error: CustomError = new CustomError();
  public isSubmitting = false;
  public showError = true;

  endDate: any;
  startDate: any;


  constructor(
    private inboxService: InboxService,
  ) { }

  ngOnInit() {
    this.startDate = moment(this.details.start_date).format("ddd, MMM Do YYYY, h:mm a");

    if(moment(this.details.start_date).format("ddd, MMM Do YYYY") === moment(this.details.end_date).format("ddd, MMM Do YYYY")) {
      this.endDate = moment(this.details.end_date).format("h:mm a");
    } else {
      this.endDate = moment(this.details.end_date).format("ddd, MMM Do YYYY, h:mm a");
    }
  }

  get attendees() {
    const {receiver, contact} = this.details;
    return receiver.length ? receiver : contact;
  }

  closeConfirmModal() {
    this.closeModal.emit(true);
  }
  
  submit() {
    this.isSubmitting = true;
    this.inboxService.createEvent(this.details)
    .then(
      resp => {
        this.isSubmitting = false;
        this.inviteSent.emit(resp);
      }
    )
    .catch(
      err => {
        this.error = new CustomError(err.error);
        this.showError = true;
        this.isSubmitting = false;
      }
    )

  }
}
