import { NgModule } from '@angular/core';

import { ScrollToTopDirective } from "./scroll-to-top.directive";

@NgModule({
  declarations: [
    ScrollToTopDirective
  ],
  exports: [ScrollToTopDirective]
})
export class DirectivesModule { }
