import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';
import { BroadcastService } from 'src/app/commons/services/broadcast.service';
import { BroadcastMessage } from 'src/app/commons/models/broadcast.models';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  public message: BroadcastMessage = new BroadcastMessage();

  constructor(
    private broadcastService: BroadcastService,
    private state: StateService
  ) { }

  ngOnInit() {
    this.broadcastService.fetchBroadcastMessageDetails(this.state.params.id);
    this.broadcastService.broadcastMessageDetails.subscribe(resp => {
      this.message = resp;
    });
  }

}
