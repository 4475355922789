// tslint:disable: variable-name
import { User } from './user.models';


export class VoiceRecordsModel {
  id: string = null;
  name = 'local';
  user: User = new User();
  csid: string = null;
  rsid: string = null;
  status: string = null;
  recording_url: string = null;
  date_created: string = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class VoiceRecordingModel {
  phone: string = null;
  number: string = null;
  service = 2;

  constructor(data = {}) {
    Object.assign(this, data);
  }

  get invalidMessage() {
    return `Invalid number ${this.phone}, Please add you country code.`;
  }
}


export class SetOutboundCallModel {
  service = 2;
  prospect: Array<any> = [];
  contacts: any = null;
  contact_list: Array<any> = [];
  record_id: string = null;
  type: Array<any> = [];

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class BroadcastType {
  type: number = null;
  label: string = null;
}
