/*
    Model class for invite
*/
export class Invite {
    event_name: string = null;
    location: string = null;
    description: string = null;
    start_date: any;
    end_date: any;
    timezone: string = null;

    constructor(data = {}) {
        Object.assign(this, data);
    }
}

export class InviteEvent {
    kind: string = null;
    etag: string = null;
    id: string = null;
    status: string = null;
    htmlLink: string = null;
    created: string = null;
    updated: string = null;
    summary: string = null;
    description: string = null;
    location: string = null;
    creator: Array<[]> = [];
    organizer: Array<[]> = [];
    start: Array<[]> = [];
    end: Array<[]> = [];
    iCalUID: string = null;
    sequence: number = 0;
    attendees: Array<[]> = [];
    reminders: Array<[]> = [];
  
    constructor(data = {}) {
      Object.assign(this, data);
    }
}

export class EventCreator {
    email: string = null;

    constructor(data = {}) {
        Object.assign(this, data);
    }
}

export class Attendee {
    email: string = null;
    organizer: boolean = false;
    self: boolean = false;
    responseStatus: string = null;
    comment: string = null;
    additionalGuests: number = 0;

    constructor(data = {}) {
        Object.assign(this, data)
    } 
}