import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side',
  templateUrl: './side.component.html',
  styleUrls: ['./side.component.scss']
})
export class SideComponent implements OnInit {
  public isBroadcastExtend = false;

  constructor(
    private state: StateService
  ) { }

  ngOnInit() {
    if (this.state.params && this.state.params.module ===  'voice') {
      this.isBroadcastExtend = true;
      return;
    }
    this.isBroadcastExtend = (this.state.current.name === 'broadcast-list');
  }

}
