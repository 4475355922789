// export class ProspectFilter {
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';

export class ProspectFilterForm {
  public form: FormGroup;
  public errors: string = null;
  public submitted = false;

  constructor(data) {
    // Initialize the form builder
    this.form = new FormBuilder().group({
      prospect_name: new FormControl(null, []),
      miles: new FormControl(0, []),
      address: new FormControl(null, []),
      bedrooms: new FormControl(null, []),
      rent_min: new FormControl(null, []),
      rent_max: new FormControl(null, []),
    });
  }

  // check form if all field is valid
  get isValid() {
    return this.form.valid;
  }

  // check if form field is valid
  valid(f: any) {
    return !(!this.form.get(f).valid && this.form.get(f).touched);
  }

  updateValue(f, value) {
    this.form.get(f).setValue(value);
  }
}
