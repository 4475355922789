import { PUBLIC_STATES } from './components/public/public.states';
import { DASHBOARD_STATES } from './components/dashboard/dashboard.states';
import { CREDIT_STATES } from './components/credit/credit.states';

export const APP_STATES = {
  otherwise : '/login',
  states    : [].concat(
    PUBLIC_STATES,
    DASHBOARD_STATES,
    CREDIT_STATES
  )
};
