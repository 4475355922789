import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Message, Inbox } from 'src/app/commons/models/inbox.models';

@Component({
  selector: 'app-convo-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class ConversationMessageComponent implements OnInit {
  @Input() convo: Message = new Message();
  @Input() inbox: Inbox = new Inbox();
  @Input() isScroll = false;
  @ViewChild("scrollMe", {static: false}) el: ElementRef;

  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if(this.isScroll) {
      this.el.nativeElement.scrollIntoView();
    }
  }

  prospectName(inbox) {
    return inbox.prospect ? inbox.prospect.name : inbox.thread_id;
  }

  senderName(inbox, message) {
    if (message.outbound) {
      return `${message.outbound.first_name} ${message.outbound.last_name}`;
    } else {
      return this.prospectName(inbox);
    }
  }

}
