import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { urlsafe, queryparams } from "../utils/http.utils";

import {
  API_VOICE_RECORDS,
  API_VOICE_RECORDING_PHONE,
  API_OUTBOUND_CALL,
  API_VOICE_RECORDING_DELETE,
} from "../constants/api.constants";
import {
  VoiceRecordsModel,
  VoiceRecordingModel,
  SetOutboundCallModel,
} from "../models/voice.models";

@Injectable({
  providedIn: "root",
})
export class VoiceService {
  public callContactData: BehaviorSubject<any> = new BehaviorSubject({});
  public outBoundCallPayload: BehaviorSubject<SetOutboundCallModel> =
    new BehaviorSubject(new SetOutboundCallModel());
  public voiceRecords$: BehaviorSubject<any[]> = new BehaviorSubject(
    Array<any>()
  );
  private recordingStatus$: BehaviorSubject<any[]> = new BehaviorSubject(
    Array<any>()
  );

  constructor(private http: HttpClient) {}

  get getVoiceRecords() {
    return this.voiceRecords$.asObservable();
  }

  get getRecordingStatus() {
    return this.recordingStatus$.asObservable();
  }

  get getoutBoundCallPayload() {
    return this.outBoundCallPayload.asObservable();
  }

  get getCallContact() {
    return this.callContactData.asObservable();
  }

  setCallContact(inbox: any) {
    const contacts = inbox.prospect ? inbox.prospect : inbox.contact;

    const prospectArray = [];
    const contactArray = [];
    if (contacts.email_id) {
      const contact_number = contacts.contact.contact_number;
      prospectArray.push({
        email_id: contacts.email_id,
        formatted_num: contact_number,
      });
    } else if (contacts.prospect && contacts.id) {
      prospectArray.push({
        email_id: contacts.prospect,
        formatted_num: contacts.contact_number,
      });
    } else {
      contactArray.push(contacts.id);
    }
    const payload = {
      prospect: prospectArray,
      contact: contactArray,
      service: 2,
    };
    this.callContactData.next(payload);
  }

  setOutBoundCallData(data: SetOutboundCallModel) {
    this.outBoundCallPayload.next(new SetOutboundCallModel(data));
  }

  fetchVoiceRecords() {
    return this.http
      .get(API_VOICE_RECORDS)
      .toPromise()
      .then((response: VoiceRecordsModel[]) => {
        this.voiceRecords$.next(response);
      });
  }

  appendNewRecord(data) {
    this.voiceRecords$.next(this.voiceRecords$.getValue().concat(data));
  }

  initiateVoiceRecord(data: VoiceRecordingModel) {
    return this.http.post(API_VOICE_RECORDING_PHONE, data).toPromise();
  }

  initiateOutBoundCall(data: SetOutboundCallModel) {
    return this.http.post(API_OUTBOUND_CALL, data).toPromise();
  }

  deleteVoiceRecord(id) {
    return this.http.post(API_VOICE_RECORDING_DELETE, { rsid: id });
  }
}
