import { urlsafe } from '../utils/http.utils';

/**
 *
 * @param id user id
 */
export const WP_ENV = (id: any) => {
  return {
    wsEndpoint: urlsafe(`ws://${window.location.host}/ws/sms-reply/`, id),
    voiceEndpoint: urlsafe(`ws://${window.location.host}/ws/voice-broadcast/`, id)
  };
};

export const environment = {
  wsEndpoint: `ws://${window.location.host}/sms-reply/`,
  voiceEndpoint: `ws://${window.location.host}/voice-broadcast/`,
};
