import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-deleteconfirm",
  templateUrl: "./deleteconfirm.component.html",
  styleUrls: ["./deleteconfirm.component.scss"],
})
export class DeleteconfirmComponent implements OnInit {
  constructor(private activemodal: NgbActiveModal) {}

  ngOnInit() {}

  close(data?) {
    this.activemodal.close(data);
  }
}
