// TODO: make it dynamic. generate every login. (it looks cool)
// 18 random characters (alphanumeric + special characters + token)
export const AUTH_KEY = 'NCqrSTxQCGMK2wDvRL1';

// Configure backend API url
export const API_URL = '/api/';

//
export const GOOGLE_API_KEY = 'AIzaSyDxiuEg9cs9J6qxe6gQFiXD03ij1MpBIks';

// Credit config
export const CREDIT_PRICE = 0.06;
export const PHONE_NUMBER_PRICE = 3.00;

// Braintree config
export const BRAINTREE_AUTH = 'production_jyfstvtv_2wz4syh98gqtrb96';
