import { ContentOnly, NavSideContent, NavContent } from 'src/app/commons/utils/layout.utils';
import { LoginRequired } from 'src/app/commons/utils/security.utils';

import { BaseComponent } from './base/base.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { AmountComponent } from './purchase/amount/amount.component';
import { ConfirmComponent } from './purchase/confirm/confirm.component';
import { ReceiptComponent } from './purchase/receipt/receipt.component';
import { PhoneComponent } from './purchase/phone/phone.component';


export const CREDIT_STATES: object[] = [
  {
    name: 'credit',
    url: '/credit',
    views: ContentOnly(BaseComponent),
    onEnter: LoginRequired,
    redirectTo: 'purchase'
  },
  {
    name: 'purchase',
    url: '',
    parent: 'credit',
    views: NavContent(PurchaseComponent)
  },
  {
    name: 'credit-amount',
    url: '/amount',
    parent: 'purchase',
    views: ContentOnly(AmountComponent)
  },
  {
    name: 'phone-select',
    url: '/phone-select',
    parent: 'purchase',
    views: ContentOnly(PhoneComponent),
    redirectTo: 'dashboard'
  },
  {
    name: 'credit-confirm',
    url: '/confirm',
    parent: 'purchase',
    views: ContentOnly(ConfirmComponent),
    params: {
      returnPage: null,
    },
  },
  {
    name: 'credit-receipt',
    url: '/receipt',
    parent: 'purchase',
    views: ContentOnly(ReceiptComponent)
  },
];
