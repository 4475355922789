import { CreditService } from 'src/app/commons/services/credit.service';
import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { CreditCardForm } from 'src/app/commons/forms/credit.forms';
import { Card, HostFieldsValidation } from 'src/app/commons/models/credit.models';

import * as braintree from 'braintree-web';
import { BRAINTREE_AUTH } from 'src/app/commons/constants/conf.constants';


@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  private hostedFieldsInstance: braintree.HostedFields;

  public form: CreditCardForm;
  public HostFieldsValidation: HostFieldsValidation = new HostFieldsValidation();
  public showErrors = false;
  public backendError = {
    show: false,
    message: null
  };
  public isSaving = false;

  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private creditService: CreditService
  ) { }

  ngOnInit() {
    this.form = new CreditCardForm(new Card());
    this.createBraintreeUI();
  }

  createBraintreeUI() {
    braintree.client.create({
      authorization: BRAINTREE_AUTH
    }).then((clientInstance) => {
      braintree.hostedFields.create({
        client: clientInstance,
        fields: {
          number: {
            selector: '#card-number',
          },
          cvv: {
            selector: '#cvv',
          },
          expirationDate: {
            selector: '#expiration-date',
            placeholder: 'MM/YY'
          }
        }
      }).then((hostedFieldsInstance) => {
        this.hostedFieldsInstance = hostedFieldsInstance;

        hostedFieldsInstance.on('blur', (event) => {
          const field = event.fields;
          this.HostFieldsValidation = new HostFieldsValidation(field);
        });
      });
    });
  }

  cleanData(object = {}) {
    Object.keys(object).forEach(element => {
      if (typeof(object[element]) === 'object' && !!object[element]) {
        this.cleanData(object[element]);
      } else if (object[element] === null || object[element] === undefined || object[element] === '') {
        delete object[element];
      }
    });

    return object;
  }

  async onSubmit({value, valid}: {value: Card, valid: boolean}) {
    const cleanedValue = this.cleanData(value);

    if (valid && this.HostFieldsValidation.isValid()) {
      this.hostedFieldsInstance.tokenize()
      .then((resp) => {
        this.isSaving = true;
        // tslint:disable-next-line: no-string-literal
        cleanedValue['payment_method_nonce'] = resp.nonce;
        this.creditService.createPaymentMethod(cleanedValue)
        .then((response) => {
          this.isSaving = false;
          this.closeModal.emit(true);
        })
        .catch((err) => {
          if (err.error) {
            const errMsg = err.error.message;
            this.backendError.message = errMsg;
          }
          this.backendError.show = true;
          this.isSaving = false;
        });
      })
      .catch((err) => {
        console.log(err);
      });
    } else {
      this.showErrors = true;
    }
  }
}
